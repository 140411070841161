import React from "react";
import { expiratedValues } from "../../models/expirated-values";
import { Tag } from "primereact/tag";

export const ExpiratedBodyTemplate = (rowData: any) => {
  const expiratedValue =
    expiratedValues.find((item) => item.value === rowData.status) ||
    expiratedValues[0];

  const getSeverity = () => {
    if (expiratedValue.severity === "success") return "success";
    if (expiratedValue.severity === "warning") return "warning";
    if (expiratedValue.severity === "danger") return "danger";
    return "info";
  };
  return (
    <div className="flex align-items-center gap-2 w-full">
      <Tag severity={getSeverity()} value={`${expiratedValue.message}`} />
    </div>
  );
};
