export const getBase64 = (file: File) => {
  return new Promise((resolve) => {
    let baseURL;
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const resizeImage = (
  file: File,
  newWidth: number,
  newHeight: number,
  callback: (resizedImageData: string) => void
) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const img = new Image();
    img.src = e.target?.result as string;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx?.drawImage(img, 0, 0, newWidth, newHeight);

      const resizedImageData = canvas.toDataURL("image/jpeg"); 
      callback(resizedImageData);
    };
  };

  reader.readAsDataURL(file);
};
