import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAssociationByIdQuery } from "../../../store/services/association";
import { useAppDispatch } from "../../../hooks/ReduxHooks";
import { actions } from "../../../store/slices/ToastSlice";
import { Skeleton } from "primereact/skeleton";
import { Avatar } from "primereact/avatar";
import {
  ActivatedBodyTemplate,
  CountryBodyTemplate,
} from "../../../components/DataTable";
import { Button } from "primereact/button";
import {
  FieldAddSports,
  FieldAddUser,
  UpdateSportForm,
} from "../../../components/SmallForms";
import { SportFetch } from "../../../models/sports";
import { Dialog } from "primereact/dialog";
import { Chip } from "primereact/chip";
import { MothlyPTable } from "./sections/MothlyPTable";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useRemoveSportMutation } from "../../../store/services/sports";
import { convertToPrice } from "../../../utils/functions/currencies";
import { useGetAssociationReqQuery } from "../../../store/services/monthly-sport-fee";
import ReqTabs from "../../../components/ReqTabs/ReqTabs";

export const SeeAssociation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { associationId } = useParams();

  const {
    isLoading,
    data,
    error,
    refetch: refetchGetAssociationById,
  } = useGetAssociationByIdQuery(associationId || "");
  const [removeSport, { isLoading: isLoadingRemove }] =
    useRemoveSportMutation();

  const {
    data: sportReqData,
    isLoading: isLoadingSportReq,
    refetch: refetchSportData,
  } = useGetAssociationReqQuery(associationId || "");

  const [canAddSport, setCanAddSport] = useState(false);
  const [canAddUser, setCanAddUser] = useState(false);
  const [deleteSport, setdeleteSport] = useState<SportFetch>();
  const [updateSport, setupdateSport] = useState<SportFetch>();

  const handleAddSport = () => setCanAddSport((prev) => !prev);
  const handleAddUser = () => setCanAddUser((prev) => !prev);

  const handleUpdateSport = () => {
    setupdateSport(undefined);
    refetchGetAssociationById();
  };

  const handleRemoveSport = () => {
    if (deleteSport?._id && associationId) {
      removeSport({
        sportId: deleteSport._id,
        associationId: associationId,
      })
        .unwrap()
        .then(() => {
          dispatch(
            actions.showSuccessToast({
              title: "Eliminar Deporte",
              message: "Deporte eliminado exitosamente!.",
            })
          );
          refetchGetAssociationById();
        })
        .catch(() =>
          dispatch(
            actions.showErrorToast({
              title: "Eliminar Deporte",
              message:
                "Ocurrió un error al intentar eliminar el deporte seleccionado, por favor intente más tarde.",
            })
          )
        );
      setdeleteSport(undefined);
    }
  };

  useEffect(() => {
    if (!associationId) return navigate("/panel/associations");
    if (error) {
      dispatch(
        actions.showErrorToast({
          title: "Obtener Asociación",
          message:
            "Ocurrió un error al intentar obtener la asociación, por favor intente más tarde.",
        })
      );
      navigate("/panel/associations");
    }
  }, [associationId, dispatch, navigate, error]);

  if (isLoading || !data) {
    return (
      <div className="p-card flex flex-column p-3">
        <div className="flex flex-column align-items-center gap-2">
          <Skeleton shape="circle" size="5rem"></Skeleton>
          <Skeleton width="10rem" className="mb-2"></Skeleton>
          <Skeleton width="8rem" className="mb-2"></Skeleton>
          <Skeleton width="30rem" height="4rem" className="mt-6"></Skeleton>
        </div>

        <div className="flex align-self-center w-12 justify-content-between mt-7">
          <Skeleton width="8rem" className="mb-2"></Skeleton>
          <Skeleton width="8rem" className="mb-2"></Skeleton>
          <Skeleton width="8rem" className="mb-2"></Skeleton>
        </div>
      </div>
    );
  }

  return (
    <div className="p-card min-h-screen flex flex-column p-4 max-w-10">
      <Avatar
        shape="circle"
        size="xlarge"
        className="align-self-center"
        image={`${
          data
            ? data.imageProfile?.imageFormat + "," + data.imageProfile?.base64
            : ""
        }`}
      />
      <h1 className="text-center m-1">
        {data.name} {ActivatedBodyTemplate(data)}
      </h1>

      <div className="flex justify-content-center align-content-center gap-1">
        <img
          alt="flag"
          src={`https://flagsapi.com/${data.countryCode}/shiny/64.png`}
          style={{ width: "22px" }}
          className="flag"
        />
        <span>
          {data.state}, {data.city}
        </span>
      </div>

      <p className="align-self-center text-center md:w-7 mb-6 ">
        {data.description}
      </p>
      <div className="grid nested-grid mb-4">
        <div className=" col-12 md:col-6">
          <div className="grid">
            <div className="col-12 text-left">
              <b className="underline">Correo </b>
              <p className="m-0">{data.email}</p>
            </div>
            <div className="col-12 md:hidden ">
              <b className="underline">CUIT </b>
              <p className="m-0">{data.cuit}</p>
            </div>
            <div className="col-12 md:hidden">
              <b className="underline">Fundación</b>
              <p className="m-0">
                {new Date(data.foundationDate).toLocaleDateString()}
              </p>
            </div>
            <div className="col-12 text-left">
              <b className="underline">Dirección</b>
              <p className="m-0">{data.address}</p>
            </div>
          </div>
        </div>
        <div className="hidden md:block col-6">
          <div className="grid">
            <div className="col-12 md:text-right">
              <b className="underline">CUIT</b>
              <p className="m-0">{data.cuit}</p>
            </div>
            <div className="col-12 md:text-right">
              <b className="underline">Fundación</b>
              <p className=" m-0">
                {new Date(data.foundationDate).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Accordion multiple activeIndex={[3]}>
        <AccordionTab header="Acciones">
          <div className="flex flex-wrap justify-content-center gap-3 mb-4">
            <Button
              label="Agregar Deportes"
              icon={canAddSport ? "pi pi-minus" : "pi pi-plus"}
              onClick={handleAddSport}
              severity={canAddSport ? "info" : undefined}
            />
            <Button
              label="Agregar Deportistas"
              icon={canAddUser ? "pi pi-minus" : "pi pi-plus"}
              onClick={handleAddUser}
              severity={canAddUser ? "info" : undefined}
            />
            <Button
              label="Agregar Administradores"
              icon={canAddUser ? "pi pi-minus" : "pi pi-plus"}
              onClick={handleAddUser}
              severity={canAddUser ? "info" : undefined}
            />
          </div>
          {canAddSport && (
            <FieldAddSports
              handleClose={() => setCanAddSport(false)}
              refetch={refetchGetAssociationById}
            />
          )}
          {canAddUser && (
            <FieldAddUser
              sportsToSelect={
                data.sports?.length
                  ? [
                      ...data.sports?.map((sport) => ({
                        id: sport._id || "",
                        name: `${sport.name} - $${sport.price}`,
                      })),
                    ]
                  : []
              }
              handleClose={() => setCanAddUser(false)}
              refetch={refetchGetAssociationById}
            />
          )}
        </AccordionTab>
        <AccordionTab header="Deportes">
          {data && Array.isArray(data.sports) && data.sports.length ? (
            <div className="grid gap-2 justify-content-center ">
              {data.sports.map((sport) => (
                <div
                  className="col-12 md:col-5 justify-content-center align-content-center border-round border-1 surface-border surface-card py-2 px-4"
                  key={sport._id}
                >
                  <div className="flex justify-content-center align-items-center gap-2 mb-2">
                    <span className="w-1"></span>
                    <h2 className="text-center underline m-0 w-8">
                      {sport.name}
                    </h2>

                    <Button
                      className="w-1"
                      severity="danger"
                      text
                      icon="pi pi-trash"
                      onClick={() => setdeleteSport(sport)}
                      disabled={isLoadingRemove}
                    />
                    <Button
                      className="w-1"
                      severity="success"
                      text
                      icon="pi pi-pencil"
                      onClick={() => setupdateSport(sport)}
                      disabled={isLoadingRemove}
                    />
                  </div>
                  <div className="flex  justify-content-center gap-2">
                    <i
                      data-pr-tooltip="Ubicación"
                      className="pi pi-map-marker text-red-700"
                    />
                    {CountryBodyTemplate(sport)}
                  </div>
                  {sport.description && <p>{sport.description}</p>}
                  <div className="flex justify-content-between">
                    <div className="text-left">
                      <b className="underline">Precio</b>
                      <p className="m-0">{convertToPrice(sport.price)}</p>
                    </div>
                    <div className="text-right">
                      <b className="underline">Vence</b>
                      <p className="m-0">{sport.expirated}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Chip
              className="mt-2 justify-content-center"
              label="Aún no ha cargado deportes"
            />
          )}
        </AccordionTab>
        <AccordionTab header="Administradores" className="w-full">
          <h1>Los admins</h1>
        </AccordionTab>
        <AccordionTab header="Cuotas" className="w-full">
          <MothlyPTable associationId={associationId || ""} />
        </AccordionTab>
      </Accordion>
      <ReqTabs
        data={sportReqData || []}
        isLoading={isLoadingSportReq}
        mode="association"
        refetch={refetchSportData}
      />

      {deleteSport && (
        <Dialog
          header="Eliminar deporte"
          visible={!!deleteSport}
          style={{ width: "40%", minWidth: 300 }}
          onHide={() => setdeleteSport(undefined)}
          footer={
            <div className="flex justify-content-end gap-3">
              <Button
                label="Quitar"
                onClick={() => handleRemoveSport()}
                severity="danger"
              />
              <Button
                label="No"
                onClick={() => setdeleteSport(undefined)}
                severity="info"
              />
            </div>
          }
        >
          <h3 className="text-red-700">
            ¡Advertencia! <i className="pi pi-exclamation-triangle"></i>
          </h3>
          <p>
            Si eliminas este deporte tambien se eliminaran sus cuotas vinculadas
          </p>
          <p className="m-0">
            ¿Estas seguro que deseas eliminar <b>{deleteSport.name}</b>?
          </p>
        </Dialog>
      )}
      {updateSport && (
        <Dialog
          header="Mopdificar deporte"
          visible={!!updateSport}
          style={{ width: "40%", minWidth: 300 }}
          onHide={() => setupdateSport(undefined)}
        >
          <UpdateSportForm
            sport={updateSport}
            handleClose={handleUpdateSport}
          />
        </Dialog>
      )}
    </div>
  );
};
