import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SportFetch } from "../../models/sports";
import { sportsTypes } from "../../utils/constants/forms-constans";
import { yupConstants } from "../../utils/constants/yup-constants";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { useParams } from "react-router-dom";
import { actions } from "../../store/slices/ToastSlice";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { useUpdateSportMutation } from "../../store/services/sports";

interface UpdateSportFormProps {
  sport: SportFetch;
  handleClose: () => void;
}

export const UpdateSportForm = ({
  sport,
  handleClose,
}: UpdateSportFormProps) => {
  const dispatch = useAppDispatch();
  const { associationId } = useParams();
  const [updateSport, { isLoading }] = useUpdateSportMutation();

  const handleUpdateSport = (data: SportFetch) => {
    if (associationId && sport._id) {
      const { name, description, expirated, price } = data;
      if (
        sport.name !== name ||
        sport.description !== description ||
        sport.price !== price ||
        sport.expirated !== expirated
      ) {
        updateSport({
          sportId: sport._id,
          associationId: associationId,
          name: name,
          description: description,
          expirated: expirated,
          price: price,
        })
          .unwrap()
          .then(() => handleClose())
          .catch(() =>
            dispatch(
              actions.showErrorToast({
                title: "Actualizar Deporte",
                message:
                  "Ocurrió un error al intentar actualizar el deporte seleccionado, por favor intente más tarde.",
              })
            )
          );
      }
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: sport,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(yupConstants().required),
      price: Yup.number()
        .required(yupConstants().required)
        .min(1, yupConstants(1).minNumber),
      expirated: Yup.number()
        .required(yupConstants().required)
        .min(1, yupConstants(1).minNumber)
        .max(28, yupConstants(28).maxNumber),
    }),
    onSubmit: handleUpdateSport,
  });
  return (
    <form className="grid mt-2" onSubmit={handleSubmit}>
      <div className="col-12 md:col-6 my-3">
        <span className="p-float-label">
          <Dropdown
            className={`min-w-full ${
              touched.name && errors.name ? "p-invalid" : ""
            }`}
            id="name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            options={[...sportsTypes]}
            filter
            disabled={isLoading}
          />
          <label htmlFor="name">Nombre</label>
        </span>
        {touched.name && errors.name && (
          <small className="p-error">{errors.name}</small>
        )}
      </div>
      <div className="col-12 md:col-6 my-3">
        <span className="p-float-label">
          <Dropdown
            className={`min-w-full ${
              touched.expirated && errors.expirated ? "p-invalid" : ""
            }`}
            id="expirated"
            name="expirated"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.expirated}
            options={[...[...Array(28).keys()]]}
            disabled={isLoading}
          />
          <label htmlFor="expirated">Vencimiento</label>
        </span>
        {touched.expirated && errors.expirated && (
          <small className="p-error">{errors.expirated}</small>
        )}
      </div>

      <div className="col-12 md:col-6 my-3">
        <span className="p-float-label">
          <InputNumber
            className={`min-w-full ${
              touched.price && errors.price ? "p-invalid" : ""
            }`}
            id="price"
            name="price"
            onChange={(e) => setFieldValue("price", e.value)}
            onBlur={handleBlur}
            value={values.price}
            min={0}
            locale="es-AR"
            disabled={isLoading}
          />
          <label htmlFor="price">Precio</label>
        </span>
        {touched.price && errors.price && (
          <small className="text-start p-error">{errors.price}</small>
        )}
      </div>

      <div className="min-w-full flex flex-column align-items-center  px-2 mt-3">
        <label className="text-3xl mb-2" htmlFor="description">
          Descripción
        </label>
        <InputTextarea
          className={`min-w-full ${
            touched.description && errors.description ? "p-invalid" : ""
          }`}
          id="description"
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          disabled={isLoading}
        />
      </div>
      <div className="flex justify-content-center w-full mt-4 gap-2">
        <Button
          className="md:hidden"
          icon="pi pi-check"
          type="submit"
          severity="success"
          loading={isLoading}
        />
        <Button
          className="hidden md:block"
          icon="pi pi-check"
          label="Actualizar"
          type="submit"
          severity="success"
          loading={isLoading}
        />
        <Button
          className="hidden md:block"
          icon="pi pi-times"
          label="Cancelar"
          type="button"
          severity="secondary"
          onClick={handleClose}
          loading={isLoading}
        />
        <Button
          className="md:hidden"
          icon="pi pi-times"
          type="button"
          severity="secondary"
          onClick={handleClose}
          loading={isLoading}
        />
      </div>
    </form>
  );
};
