import { useNavigate } from "react-router-dom";
import { useAppSelector } from "./ReduxHooks";
import { AuthStateProps } from "../models/auth";
import { shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";

export const useUserOptions = () => {
  const { user } = useAppSelector(
    (state: { auth: AuthStateProps }) => state.auth,
    shallowEqual
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userOptions = [
    ...(user
      ? [
          {
            label: "Mí perfil",
            icon: "pi pi-id-card",
            command: () => navigate("/profile"),
            path: "/panel",
          },
          {
            label: "Panel",
            icon: "pi pi-align-left",
            command: () => navigate("/panel"),
            path: "/panel",
          },
          // {
          //   label: "Configuración",
          //   icon: "pi pi-cog",
          //   command: () => navigate("/panel"),
          //   path: "/panel",
          // },
          { separator: true },
          {
            label: t("user.logout"),
            icon: "pi pi-sign-out",
            command: () => navigate("/signout"),
            // command: () => dispatch(actions.signOut()),
            path: "/signout",
          },
        ]
      : [
          {
            label: t("user.signIn"),
            icon: "pi pi-sign-in",
            command: () => navigate("/panel"),
            url: "/panel",
          },
        ]),
  ];

  return userOptions;
};
