import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Themes } from "../../models/enums/enums";
import { ThemeStateProps } from "../../models/theme";

const initialState: ThemeStateProps = {
  theme: Themes.Dark,
};

export const themeSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    changeTheme: (state, actions: PayloadAction<Themes>) => {
      const { payload } = actions;
      state.theme = payload;
    },
  },
});

export const { actions } = themeSlice;
export default themeSlice.reducer;
