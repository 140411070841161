import { useTranslation } from "react-i18next";

export const useNavePagesItems = () => {
  const { t } = useTranslation();

  const navPages = [
    {
      url: "/",
      name: t("navbar.home"),
      icon: "pi pi-home",
    },
    {
      url: "/news",
      name: t("navbar.news"),
      icon: "pi pi-info-circle",
    },
    {
      url: "/associations",
      name: t("navbar.associations"),
      icon: "pi pi-users",
    },
    {
      url: "/calendar",
      name: t("navbar.calendar"),
      icon: "pi pi-calendar",
    },
  ];

  return navPages;
};
