import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { useGetUserByIdQuery } from "../../store/services/user";
import { actions } from "../../store/slices/ToastSlice";
import { ActivatedBodyTemplate } from "../DataTable";
import EditUserData from "./EditUserData";
import { AccordionTab, Accordion } from "primereact/accordion";
import UserSportFees from "./UserSportFees";
import UserPayments from "../../pages/PanelPages/Associations/sections/UserPayments";

interface UserDataProps {
  userId?: string;
}

const UserData = ({ userId }: UserDataProps) => {
  const [editData, setEditData] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, data, error } = useGetUserByIdQuery(userId || "");

  const handleEditData = () => setEditData((prev) => !prev);

  useEffect(() => {
    if (!userId) return navigate(-1);
    if (error) {
      dispatch(
        actions.showErrorToast({
          title: "Obtener Usuairo",
          message:
            "Ocurrió un error al intentar obtener el usuario, por favor intente más tarde.",
        })
      );
      navigate(-1);
    }
  }, [error, dispatch, navigate, userId]);

  if (isLoading || !data) {
    return (
      <div className="p-card flex flex-column p-3">
        <div className="flex flex-column align-items-center gap-2">
          <Skeleton shape="circle" size="5rem"></Skeleton>
          <Skeleton width="10rem" className="mb-2"></Skeleton>
          <Skeleton width="8rem" className="mb-2"></Skeleton>
          <Skeleton width="30rem" height="4rem" className="mt-6"></Skeleton>
        </div>

        <div className="flex align-self-center w-12 justify-content-between mt-7">
          <Skeleton width="8rem" className="mb-2"></Skeleton>
          <Skeleton width="8rem" className="mb-2"></Skeleton>
          <Skeleton width="8rem" className="mb-2"></Skeleton>
        </div>
      </div>
    );
  }

  return (
    <div className="p-card flex flex-column p-4">
      <div className="flex flex-row-reverse">
        <Button
          className="w-1"
          severity={editData ? "danger" : "success"}
          text
          icon={editData ? "pi pi-times" : "pi pi-pencil"}
          badge={!!data.complete ? "" : "!"}
          badgeClassName="bg-red-500 text-white"
          tooltip={!!data.complete ? "" : "Actualizar datos"}
          tooltipOptions={{ position: "top" }}
          onClick={handleEditData}
        />
      </div>
      {editData ? (
        <EditUserData data={data} />
      ) : (
        <>
          <Avatar
            shape="circle"
            size="xlarge"
            className="align-self-center"
            label={data.firstName ? data.firstName[0] : ""}
            image={`assets/images/user.png`}
          />
          {data.lastName && data.lastName ? (
            <h1 className="text-center m-1">
              {data.lastName}, {data.firstName} {ActivatedBodyTemplate(data)}
            </h1>
          ) : (
            <h1 className="text-center m-1">Sin datos del usuario</h1>
          )}

          <div className="flex justify-content-center align-content-center gap-1">
            <img
              alt="flag"
              src={
                data.countryCode
                  ? `https://flagsapi.com/${data.countryCode}/shiny/64.png`
                  : ""
              }
              style={{ width: "22px" }}
              className="flag"
            />
            <span>
              {data.state}, {data.city} ({data.postCode})
            </span>
          </div>
          <div className="grid nested-grid my-4">
            <div className=" col-12 md:col-4">
              <div className="grid">
                <div className="col-12 text-left">
                  <b className="underline">Correo </b>
                  <p className="m-0">{data.email || "Sin datos"}</p>
                </div>
                <div className="col-12 md:hidden ">
                  <b className="underline">
                    {data.documentType || "Sin datos"}{" "}
                  </b>
                  <p className="m-0">{data.document || "Sin datos"}</p>
                </div>
                <div className="col-12 md:hidden">
                  <b className="underline">Celular</b>
                  <p className="m-0">{data.phone || "Sin datos"}</p>
                </div>
                <div className="col-12 md:hidden">
                  <b className="underline">Cto. Emergencia: </b>
                  <p className="m-0">
                    {data.emergencyContact ? `(${data.emergencyContact}) ` : ""}
                    {data.emergencyPhone || "Sin datos"}
                  </p>
                </div>

                <div className="col-12 md:hidden">
                  <b className="underline">Fecha de Nacimiento</b>
                  <p className="m-0">
                    {data.birthDate
                      ? new Date(data.birthDate || "").toLocaleDateString()
                      : "Sin datos"}
                  </p>
                </div>
                <div className="col-12 text-left">
                  <b className="underline">Sexo</b>
                  <p className="m-0">{data.sex || "Sin datos"}</p>
                </div>
              </div>
            </div>
            <div className="hidden md:block col-4">
              <div className="grid">
                <div className="col-12 text-center">
                  <b className="underline">Celular</b>
                  <p className="m-0">{data.phone || "Sin datos"}</p>
                </div>
                <div className="col-12 text-center">
                  <b className="underline">Cto. Emergencia: </b>
                  <p className="m-0">
                    {data.emergencyContact ? `(${data.emergencyContact}) ` : ""}
                    {data.emergencyPhone || "Sin datos"}
                  </p>
                </div>
              </div>
            </div>

            <div className="hidden md:block col-4">
              <div className="grid">
                <div className="col-12 text-right">
                  <b className="underline">
                    {data.documentType || "Sin datos"}{" "}
                  </b>
                  <p className="m-0">{data.document || "Sin datos"}</p>
                </div>
                <div className="col-12 text-right">
                  <b className="underline">Fecha de Nacimiento</b>
                  <p className="m-0">
                    {data.birthDate
                      ? new Date(data.birthDate || "").toLocaleDateString()
                      : "Sin datos"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Deportes activos">
              <UserSportFees />
            </AccordionTab>
            <AccordionTab header="Historial de cuotas">
              <UserPayments userId={userId || ""} />
            </AccordionTab>
          </Accordion>
        </>
      )}
    </div>
  );
};

export default UserData;
