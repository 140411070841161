import React from "react";
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { yupConstants } from "../../utils/constants/yup-constants";
import { Dropdown } from "primereact/dropdown";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { actions } from "../../store/slices/ToastSlice";
import { emptySport, sportsTypes } from "../../utils/constants/forms-constans";
import { useAddSportMutation } from "../../store/services/sports";

interface FieldAddSportsProps {
  refetch?: () => void;
  handleClose: () => void;
}

export const FieldAddSports = ({
  handleClose,
  refetch,
}: FieldAddSportsProps) => {
  const dispatch = useAppDispatch();
  const { associationId } = useParams();

  const expiratedDays = [...[...Array(29).keys()]];
  expiratedDays.shift();

  const [addSport, { isLoading }] = useAddSportMutation();

  return (
    <Formik
      initialValues={{ sports: [emptySport] }}
      validationSchema={Yup.object().shape({
        sports: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(yupConstants().required),
            price: Yup.number()
              .required(yupConstants().required)
              .min(1, yupConstants(1).minNumber),
            expirated: Yup.number()
              .required(yupConstants().required)
              .min(1, yupConstants(1).minNumber)
              .max(28, yupConstants(28).maxNumber),
          })
        ),
      })}
      onSubmit={(values) => {
        if (associationId) {
          addSport({ id: associationId, sports: values.sports })
            .unwrap()
            .then(() => {
              dispatch(
                actions.showSuccessToast({
                  title: "Agregar Deportes",
                  message: "Los deportes fueron agregados exitosamente!.",
                })
              );
              if (refetch) refetch();
              handleClose();
            })
            .catch(() =>
              dispatch(
                actions.showErrorToast({
                  title: "Agregar Deportes",
                  message:
                    "Ocurrió un error al intentar agregar los deportas a la asociación, por favor intente más tarde.",
                })
              )
            );
        }
      }}
    >
      {({ values, handleSubmit, handleChange, handleBlur }) => (
        <Form className="w-full my-2" onSubmit={handleSubmit}>
          <FieldArray name="sports">
            {({ remove, push }) => (
              <Fieldset
                className="w-full"
                legend={
                  <div className="flex align-items-center gap-2">
                    <span className="font-bold">Deportes</span>
                    <Button
                      size="small"
                      rounded
                      text
                      onClick={() => push(emptySport)}
                    >
                      <i className="pi pi-plus"></i>
                    </Button>
                  </div>
                }
              >
                <>
                  {values.sports.length > 0 ? (
                    <>
                      {values.sports.map((friend, index) => (
                        <div
                          className="grid shadow-2 border-round border-1 surface-border surface-card  mb-4"
                          key={index}
                        >
                          <Field
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`sports.${index}.name`}
                          >
                            {({ field, meta, form }: FieldProps) => (
                              <div className="col-12 lg:col-3 my-1 flex flex-column gap-2">
                                <label
                                  className="text-lg"
                                  htmlFor={`sports.${index}.name`}
                                >
                                  Nombre
                                </label>
                                <Dropdown
                                  value={meta.value}
                                  options={[...sportsTypes]}
                                  onChange={(e) =>
                                    form.setFieldValue(
                                      `sports.${index}.name`,
                                      e.value
                                    )
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <small className="p-error">
                                    {meta.error}
                                  </small>
                                )}
                              </div>
                            )}
                          </Field>
                          <Field
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`sports.${index}.description`}
                          >
                            {({ field, meta }: FieldProps) => (
                              <div className="col-12 lg:col-3 my-1 flex flex-column gap-2">
                                <label
                                  className="text-lg"
                                  htmlFor={`sports.${index}.description`}
                                >
                                  Descripción
                                </label>
                                <input
                                  {...field}
                                  className={`p-inputtext p-component p-filled ${
                                    meta.touched && meta.error
                                      ? "p-invalid"
                                      : ""
                                  }`}
                                  type="text"
                                />
                                {meta.touched && meta.error && (
                                  <small className="p-error">
                                    {meta.error}
                                  </small>
                                )}
                              </div>
                            )}
                          </Field>

                          <Field
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`sports.${index}.expirated`}
                          >
                            {({ field, meta, form }: FieldProps) => (
                              <div className="col-6 lg:col-2 my-1 flex flex-column gap-2">
                                <label
                                  className="text-lg"
                                  htmlFor={`sports.${index}.expirated`}
                                >
                                  Vencimiento
                                </label>
                                <Dropdown
                                  value={meta.value}
                                  options={expiratedDays}
                                  onChange={(e) =>
                                    form.setFieldValue(
                                      `sports.${index}.expirated`,
                                      e.value
                                    )
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <small className="p-error">
                                    {meta.error}
                                  </small>
                                )}
                              </div>
                            )}
                          </Field>

                          <Field
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`sports.${index}.price`}
                          >
                            {({ field, meta }: FieldProps) => (
                              <div className="col-6 lg:col-2 my-1 flex flex-column gap-2">
                                <label
                                  className="text-lg"
                                  htmlFor={`sports.${index}.price`}
                                >
                                  Precio
                                </label>
                                <input
                                  {...field}
                                  className={`p-inputtext p-component p-filled ${
                                    meta.touched && meta.error
                                      ? "p-invalid"
                                      : ""
                                  }`}
                                  type="number"
                                  step="1"
                                />
                                {meta.touched && meta.error && (
                                  <small className="p-error">
                                    {meta.error}
                                  </small>
                                )}
                              </div>
                            )}
                          </Field>

                          <div className="col-12 lg:col-1 h-7rem flex flex-row-reverse">
                            <Button
                              className="mt-4"
                              onClick={() => remove(index)}
                              severity="danger"
                              text
                            >
                              <i className="pi pi-trash text-3xl"></i>
                            </Button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-content-center gap-2">
                        <Button
                          className="md:hidden"
                          icon="pi pi-check"
                          type="submit"
                          severity="success"
                          loading={isLoading}
                        />
                        <Button
                          className="hidden md:block"
                          icon="pi pi-check"
                          label="Cargar"
                          type="submit"
                          severity="success"
                          loading={isLoading}
                        />
                        <Button
                          className="hidden md:block"
                          icon="pi pi-times"
                          label="Cancelar"
                          type="button"
                          severity="secondary"
                          onClick={handleClose}
                          loading={isLoading}
                        />
                        <Button
                          className="md:hidden"
                          icon="pi pi-times"
                          type="button"
                          severity="secondary"
                          onClick={handleClose}
                          loading={isLoading}
                        />
                      </div>
                    </>
                  ) : (
                    <h2 className="text-center">
                      Presione el botón "+" para agregar un deporte
                    </h2>
                  )}
                </>
              </Fieldset>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};
