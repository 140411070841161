import React from "react";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

export const CountryBodyFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
  data: any
) => {
  const countryItemFilter = (option: string) => {
    const countryCodeFind = data?.find(
      ({ country }: { country: string }) => option === country
    );
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt="flag"
          src={`https://flagsapi.com/${countryCodeFind?.countryCode}/shiny/64.png`}
          style={{ width: "22px" }}
          className="flag"
        />
        {option}
      </div>
    );
  };

  return (
    <Dropdown
      value={options.value}
      options={[
        ...new Set<string>(
          data?.map(({ country }: { country: string }) => country)
        ),
      ]}
      onChange={(e: DropdownChangeEvent) =>
        options.filterApplyCallback(e.value)
      }
      placeholder="Seleccionar uno"
      className="p-column-filter"
      showClear
      itemTemplate={countryItemFilter}
      style={{ minWidth: "12rem" }}
    />
  );
};
