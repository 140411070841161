/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { shallowEqual } from "react-redux";
import { toastStateProps } from "../../models/toast";
import { actions } from "../../store/slices/ToastSlice";
export const ToastCustom = () => {
  const dispatch = useAppDispatch();

  const { show, title, message, type } = useAppSelector(
    (state: { toast: toastStateProps }) => state.toast,
    shallowEqual
  );
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (show) {
      toast.current?.show({
        severity: type,
        summary: title,
        detail: message,
        life: 4000,
      });
    }
  }, [show]);

  const closeToast = () => {
    dispatch(actions.hideToast());
    toast.current?.clear();
  };

  return (
    <Toast
      ref={toast}
      position="bottom-right"
      onRemove={closeToast}
      onHide={closeToast}
    />
  );
};
