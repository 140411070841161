import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

export const SpinnerPage = () => {
  return (
    <div className="card flex justify-content-center">
      <ProgressSpinner />
    </div>
  );
};
