import { shallowEqual } from "react-redux";
import UserData from "../../../components/User/UserData";
import { useAppSelector } from "../../../hooks/ReduxHooks";
import { AuthStateProps } from "../../../models/auth";

export const Profile = () => {
  const { user } = useAppSelector(
    (state: { auth: AuthStateProps }) => state.auth,
    shallowEqual
  );

  return <UserData userId={user?._id || ""} />;
};
