import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import SpanishES from './es-AR.json'
// import EnglishUS from './en-US.json'

const resources = {
  // en: {
  //   translation: EnglishUS
  // },
  es: {
    translation: SpanishES
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: "es", 
    fallbackLng: "es",

    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;