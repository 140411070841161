import React from "react";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import {
  TriStateCheckbox,
  TriStateCheckboxChangeEvent,
} from "primereact/tristatecheckbox";

export const VerifiedRowFilterTemplate = (
  options: ColumnFilterElementTemplateOptions
) => {
  return (
    <TriStateCheckbox
      value={options.value}
      onChange={(e: TriStateCheckboxChangeEvent) =>
        options.filterApplyCallback(e.value)
      }
    />
  );
};
