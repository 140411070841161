/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "./components/Header";
import { PrimeReactProvider } from "primereact/api";
import { Outlet } from "react-router-dom";
import { ToastCustom } from "../../components/ToastCustom/ToastCustom";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";

I18n.putVocabularies(translations);
I18n.setLanguage("es");
export const PageLayout = () => {
  return (
    <PrimeReactProvider>
      <div className="flex flex-column justify-content-between min-h-screen">
        <section>
          <Header />
          <main>
            <Outlet />
          </main>
        </section>
        <footer>Soy el footer</footer>
      </div>

      <ToastCustom />
    </PrimeReactProvider>
  );
};
