import React from "react";
import { getYearsOld } from "../../utils/functions/date-helper";

export const UserNameBodyTemplate = (rowData: any) => {
  return (
    <div className="flex align-items-center gap-2 w-full">
      <span>
        {rowData.lastName}, {rowData.firstName}
        {rowData.birthDate && ` (${getYearsOld(rowData.birthDate.toString())})`}
      </span>
    </div>
  );
};
