import { RemoveSportById } from "../../models/association";
import {
  AddSportInAssociationForm,
  UpdateSportData,
  GetSportsQuery,
} from "../../models/forms";
import { SportFetch } from "../../models/sports";
import { api } from "./api";

export const sportApi = api.injectEndpoints({
  endpoints: (build) => ({
    removeSport: build.mutation<void, RemoveSportById>({
      query: (data) => ({
        url: `sports/remove`,
        method: "DELETE",
        body: data,
      }),
    }),
    addSport: build.mutation<void, AddSportInAssociationForm>({
      query: (data) => ({
        url: `sports/create`,
        method: "POST",
        body: data,
      }),
    }),
    updateSport: build.mutation<void, UpdateSportData>({
      query: (data) => ({
        url: `sports/update`,
        method: "PATCH",
        body: data,
      }),
    }),
    getSports: build.query<SportFetch[], GetSportsQuery>({
      query: (data) => ({
        url: `sports/getSports`,
        params: data,
      }),
    }),
  }),
});

export const {
  useRemoveSportMutation,
  useAddSportMutation,
  useUpdateSportMutation,
  useGetSportsQuery,
} = sportApi;
