import React from "react";
import { UserMothlySportFee } from "../../models/monthly-sport-fee";
import * as Yup from "yup";
import { useFormik } from "formik";
import { yupConstants } from "../../utils/constants/yup-constants";
import { PayMonthlySportForm } from "../../models/forms";
import { MonthlyFee, PayMethods } from "../../models/enums/enums";
import { Dropdown } from "primereact/dropdown";
import { payMethods } from "../../utils/constants/forms-constans";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { convertToPrice } from "../../utils/functions/currencies";
import { useCreateUserMothlySportPaymentMutation } from "../../store/services/monthly-sport-payment";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { actions } from "../../store/slices/ToastSlice";

interface PayMonthlySportProps {
  fee: UserMothlySportFee;
  selectMonth: number;
  selectYear: number;
  handleClose: () => void;
  reload: () => void;
}

export const PayMonthlySport = ({
  fee,
  selectMonth,
  selectYear,
  handleClose,
  reload,
}: PayMonthlySportProps) => {
  const dispatch = useAppDispatch();

  const [createUserMothlySportPayment, { isLoading }] =
    useCreateUserMothlySportPaymentMutation();

  const handlePayMonthlySport = ({
    amount,
    method,
    month,
    year,
    totalPayment,
  }: PayMonthlySportForm) => {
    createUserMothlySportPayment({
      amount,
      method,
      month,
      year,
      partialPayment: !totalPayment,
      associationId: fee.associationId,
      monthlySportFeeId: fee.monthlySportFeeId,
      sportId: fee.sportId,
      userId: fee.userId,
    })
      .unwrap()
      .then(() => {
        dispatch(
          actions.showSuccessToast({
            title: "Pagar cuota",
            message: "¡Cuota registrada exitosamente!.",
          })
        );
        reload();
        handleClose();
      })
      .catch(() =>
        dispatch(
          actions.showErrorToast({
            title: "Pagar cuota",
            message:
              "Ocurrió un error al intentar pagar la cuota, por favor intente más tarde.",
          })
        )
      );
  };

  const initialValues: PayMonthlySportForm = {
    method: PayMethods.CASH,
    amount: fee.debt,
    totalPayment: !!!fee.debt && fee.status !== MonthlyFee.UNPAID,
    month: selectMonth,
    year: selectYear,
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      method: Yup.string().required(yupConstants().required),
      amount: Yup.number()
        .required(yupConstants().required)
        .min(1, yupConstants(1).minNumber)
        .max(fee.price, yupConstants(fee.price).minNumber),
    }),
    onSubmit: handlePayMonthlySport,
  });
  return (
    <form className="grid mt-2" onSubmit={handleSubmit}>
      <h3 className="col-12 m-0 text-center">Deporte: {fee.sportName}</h3>
      <h3 className="col-6 text-green-700">
        Precio: {convertToPrice(fee.price)}
      </h3>
      <h3 className="col-6 text-right text-red-700">
        Deuda: {convertToPrice(fee.debt)}
      </h3>

      <div className="col-12 my-3">
        <span className="p-float-label">
          <Dropdown
            className={`min-w-full ${
              touched.method && errors.method ? "p-invalid" : ""
            }`}
            id="method"
            name="method"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.method}
            options={[...payMethods]}
            disabled={isLoading}
          />
          <label htmlFor="method">Método de pago</label>
        </span>
        {touched.method && errors.method && (
          <small className="p-error">{errors.method}</small>
        )}
      </div>
      {fee.debt === fee.price && (
        <div className="col-12 my-3 flex justify-content-center align-items-center gap-4">
          <label htmlFor="totalPayment">
            ¿Pagar el valor total de la cuota?
          </label>
          <InputSwitch
            id="totalPayment"
            name="totalPayment"
            checked={values.totalPayment}
            // onChange={handleChange}
            onChange={(e) => {
              const { value } = e;
              setFieldValue(
                "totalPayment",
                fee.debt !== fee.price ? false : value
              );
              setFieldValue(
                "amount",
                fee.debt !== fee.price ? fee.debt : fee.price
              );
            }}
          />
        </div>
      )}

      <div className="col-12 my-3">
        <span className="p-float-label">
          <InputNumber
            className={`min-w-full ${
              touched.amount && errors.amount ? "p-invalid" : ""
            }`}
            id="amount"
            name="amount"
            // onChange={handleChange}
            onChange={(e) => setFieldValue("amount", e.value)}
            onBlur={handleBlur}
            value={values.amount}
            min={0}
            max={
              fee.debt === fee.price || fee.status === MonthlyFee.UNPAID
                ? fee.price
                : fee.debt
            }
            locale="es-AR"
            disabled={values.totalPayment}
          />
          <label htmlFor="amount">Monto a pagar</label>
        </span>
        {touched.amount && errors.amount && (
          <small className="p-error">{errors.amount}</small>
        )}
      </div>
      <div className="flex justify-content-center w-full mt-4 gap-2">
        <Button
          className="md:hidden"
          icon="pi pi-check"
          type="submit"
          severity="success"
          loading={isLoading}
        />
        <Button
          className="hidden md:block"
          icon="pi pi-check"
          label="Pagar"
          type="submit"
          severity="success"
          loading={isLoading}
        />
        <Button
          className="hidden md:block"
          icon="pi pi-times"
          label="Cancelar"
          type="button"
          severity="secondary"
          onClick={handleClose}
          loading={isLoading}
        />
        <Button
          className="md:hidden"
          icon="pi pi-times"
          type="button"
          severity="secondary"
          onClick={handleClose}
          loading={isLoading}
        />
      </div>
    </form>
  );
};
