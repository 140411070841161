import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { useUserOptions } from "../../../hooks/useUserOptions";
import ThemeButton from "../../../components/ThemeButton/ThemeButton";

const UserButton = () => {
  const menuRight = useRef<Menu>(null);
  const userOptions = useUserOptions();
  const options = [
    ...userOptions,
    {
      label: "cambiar tema",
      icon: "pi pi-key",
      template: () => <ThemeButton />,
    },
  ];

  return (
    <div className="hidden md:flex">
      <Button
        rounded
        icon="pi pi-user"
        className="mr-2"
        onClick={(event: any) => {
          if (menuRight.current) menuRight.current.toggle(event);
        }}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
      <Menu
        popup
        ref={menuRight}
        model={options}
        id="popup_menu_right"
        popupAlignment="right"
      />
    </div>
  );
};

export default UserButton;
