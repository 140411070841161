import {
  Association,
  GetAssociationsQuery,
  NewAssociation,
} from "../../models/association";
import { api } from "./api";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAssociations: build.query<Association[], GetAssociationsQuery>({
      query: (data) => ({
        url: `associations`,
        params: data,
      }),
    }),
    newAssociation: build.mutation<NewAssociation, NewAssociation>({
      query: (data) => ({
        url: "associations/create",
        method: "POST",
        body: data,
      }),
    }),
    getAssociationById: build.query<Association, string>({
      query: (id) => ({
        url: `associations/getAssociationById/${id}`,
      }),
    }),
  }),
});

export const {
  useNewAssociationMutation,
  useGetAssociationByIdQuery,
  useGetAssociationsQuery,
} = userApi;
