export const yupConstants = (value?: string | number) => {
  return {
    email: "Debe ingresar un email valido",
    max: `Solo se permiten ${value} caracteres`,
    min: `Debe ingresar al menos ${value} caracteres`,
    required: "Este campo es obligatorio",
    passwordEquals: "Las contraseñas no coinciden",
    image: "Debe seleccionar una imagen",
    minArray: `Debe seleccionar al menos ${value}`,
    maxArray: `Debe seleccionar más de ${value}`,
    minNumber: `Debe ingeresar un valor mayor de ${value}`,
    maxNumber: `Debe ingeresar un menor mayor de ${value}`,
  };
};
