import { Avatar } from "primereact/avatar";
import React from "react";

export const AssociationNameBodyTemplate = (rowData: any) => {
  return (
    <div className="flex align-items-center gap-2 w-full">
      <Avatar
        shape="circle"
        image={`${rowData.imageProfile?.imageFormat},${rowData.imageProfile?.base64}`}
      />
      <span>{rowData.name}</span>
    </div>
  );
};
