import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";

const CompleteProfile = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-column justify-content-center align-items-center">
      <h4>
        Necesita completar sus datos de usuario para acceder a esta sección
      </h4>
      <Button label="Completar" onClick={() => navigate("/profile")} />
    </div>
  );
};

export default CompleteProfile;
