import React from "react";
import UserSportFees from "../../../components/User/UserSportFees";

export const SportMonthlyFees = () => {
  return (
    <div className="p-card flex flex-column p-3 w-full">
      <h1 className="text-center">Mis cuotas activas</h1>
      <UserSportFees />
    </div>
  );
};
