import React from "react";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

export const SexBodyFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
  data: any
) => {
  return (
    <Dropdown
      value={options.value}
      options={[
        ...new Set<string>(
          data?.map(
            ({ sex }: { sex: string }) => sex.charAt(0).toUpperCase() || ""
          )
        ),
      ]}
      onChange={(e: DropdownChangeEvent) =>
        options.filterApplyCallback(e.value)
      }
      placeholder="Sexo"
      className="p-column-filter "
      showClear
      style={{ minWidth: "80px" }}
    />
  );
};
