import { Authenticator } from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";
import authConfig from "../aws-config";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
Amplify.configure(authConfig);

const AuthGuard = () => {
  return (
    <Authenticator.Provider>
      <Authenticator>
        <Outlet />
      </Authenticator>
    </Authenticator.Provider>
  );
};

export default AuthGuard;
