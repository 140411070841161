import {
  GetUserPayments,
  MonthPaymentSummary,
  RemoveMothlySportPayment,
  UserMothlySportPayment,
} from "../../models/monthly-sport-payment";
import { api } from "./api";

export const monthlySportsPaynmentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createUserMothlySportPayment: build.mutation<void, UserMothlySportPayment>({
      query: (data) => ({
        url: `monthly-sports-payments/create`,
        method: "POST",
        body: data,
      }),
    }),
    removeSportPayment: build.mutation<boolean, RemoveMothlySportPayment>({
      query: (data) => ({
        url: `monthly-sports-payments/remove`,
        method: "DELETE",
        body: data,
      }),
    }),
    getUserPayments: build.query<MonthPaymentSummary[], GetUserPayments>({
      query: ({ userId, year }) => ({
        url: `monthly-sports-payments/getUserPayments/${userId}/${year}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateUserMothlySportPaymentMutation,
  useRemoveSportPaymentMutation,
  useGetUserPaymentsQuery,
} = monthlySportsPaynmentsApi;
