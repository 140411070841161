/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useNewAssociationMutation } from "../../../store/services/association";
import * as Yup from "yup";
import { useFormik } from "formik";
import { actions } from "../../../store/slices/ToastSlice";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { yupConstants } from "../../../utils/constants/yup-constants";
import { NewAssociationForm } from "../../../models/forms";
import { useAppDispatch } from "../../../hooks/ReduxHooks";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import countriesAndRegions from "../../../utils/jsons/countriesAndRegions.json";
import { Calendar } from "primereact/calendar";
import { InputFileCustom } from "../../../components/InputFileCustom/InputFileCustom";
import { NewAssociation } from "../../../models/association";
import { InputTextarea } from "primereact/inputtextarea";

export const NewAssociations = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [newAssociation, { isLoading }] = useNewAssociationMutation();

  const handleNewAssociation = (values: NewAssociationForm) => {
    const { imageFormat, base64, ...rest } = values;
    const newAssociationValues: NewAssociation = {
      ...rest,
      imageProfile: {
        imageFormat,
        base64,
      },
    };
    newAssociation(newAssociationValues)
      .unwrap()
      .then(() => {
        resetForm();
        dispatch(
          actions.showSuccessToast({
            title: "Crear Asociación",
            message: "Asociación registrada exitosamente!.",
          })
        );
        navigate("/panel/associations");
      })
      .catch(() =>
        dispatch(
          actions.showErrorToast({
            title: "Crear Asociación",
            message:
              "Ocurrió un error al intentar crear asociación, por favor intente más tarde.",
          })
        )
      );
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: "",
      cuit: "",
      name: "",
      description: "",
      instagram: "",
      facebook: "",
      phone: "",
      sports: undefined,
      address: "",
      country: "",
      countryCode: "",
      state: "",
      city: "",
      foundationDate: "",
      imageFormat: "",
      base64: "",
    } as NewAssociationForm,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(yupConstants().email)
        .required(yupConstants().required),
      cuit: Yup.string().required(yupConstants().required),
      name: Yup.string().required(yupConstants().required),
      description: Yup.string().required(yupConstants().required),
      // sports: Yup.array()
      //   .min(1, yupConstants(1).minArray)
      //   .required(yupConstants().required),
      address: Yup.string().required(yupConstants().required),
      country: Yup.string().required(yupConstants().required),
      state: Yup.string().required(yupConstants().required),
      city: Yup.string().required(yupConstants().required),
      foundationDate: Yup.date().required(yupConstants().required),
      base64: Yup.string().required(yupConstants().image),
      sports: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(yupConstants().required),
          price: Yup.number().required(yupConstants().required),
          description: Yup.string().required(yupConstants().required),
          expirationDay: Yup.number().required(yupConstants().required),
        })
      ),
    }),
    onSubmit: handleNewAssociation,
  });

  const regionOptions = useMemo(() => {
    if (values.country) {
      const result = countriesAndRegions.find(
        (country) => country.countryName === values.country
      );
      setFieldValue("countryCode", result?.countryShortCode);

      return result?.regions.map((region) => region.name);
    }
    setFieldValue("countryCode", "");
    return [];
  }, [values.country]);

  const handleInputFile = (value: string) => {
    const imageValues = value.split(",");
    setFieldError("base64", undefined);
    setFieldValue("imageFormat", imageValues[0]);
    setFieldValue("base64", imageValues[1]);
  };
  const handleInputFileClear = () => {
    setFieldValue("imageFormat", "");
    setFieldValue("base64", "");
    setFieldError("base64", yupConstants().image);
  };

  return (
    <div className="p-card flex flex-column p-3">
      <h1 className="text-center">Crear Asociación</h1>
      <form className="grid " onSubmit={handleSubmit}>
        <div className="col-12 flex flex-column align-items-center justify-content-center mt-2">
          <InputFileCustom
            handleInputFile={handleInputFile}
            handleInputFileClear={handleInputFileClear}
            error={errors.base64 && errors.base64}
          />
        </div>

        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.name && errors.name ? "p-invalid" : ""
              }`}
              id="name"
              name="name"
              keyfilter={/[\w ]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              disabled={isLoading}
            />
            <label htmlFor="name">Nombre</label>
          </span>
          {touched.name && errors.name && (
            <small className="text-start p-error">{errors.name}</small>
          )}
        </div>

        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.cuit && errors.cuit ? "p-invalid" : ""
              }`}
              id="cuit"
              name="cuit"
              keyfilter={/[\0-9-]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cuit}
              disabled={isLoading}
            />
            <label htmlFor="cuit">CUIT</label>
          </span>
          {touched.cuit && errors.cuit && (
            <small className="p-error">{errors.cuit}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.email && errors.email ? "p-invalid" : ""
              }`}
              id="email"
              name="email"
              keyfilter="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isLoading}
            />
            <label htmlFor="email">Correo</label>
          </span>
          {touched.email && errors.email && (
            <small className="p-error">{errors.email}</small>
          )}
        </div>

        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.phone && errors.phone ? "p-invalid" : ""
              }`}
              id="phone"
              name="phone"
              keyfilter="alphanum"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              disabled={isLoading}
            />
            <label htmlFor="phone">Celular</label>
          </span>
        </div>

        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.instagram && errors.instagram ? "p-invalid" : ""
              }`}
              id="instagram"
              name="instagram"
              keyfilter="alphanum"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.instagram}
              disabled={isLoading}
            />
            <label htmlFor="instagram">Instagram</label>
          </span>
        </div>

        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.facebook && errors.facebook ? "p-invalid" : ""
              }`}
              id="facebook"
              name="facebook"
              keyfilter="alphanum"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.facebook}
              disabled={isLoading}
            />
            <label htmlFor="facebook">Facebook</label>
          </span>
        </div>

        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <Dropdown
              className={`min-w-full ${
                touched.country && errors.country ? "p-invalid" : ""
              }`}
              id="country"
              name="country"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              options={countriesAndRegions.map(
                (countries) => countries.countryName
              )}
              placeholder="Selecciona tu país"
              filter
              disabled={isLoading}
            />
            <label htmlFor="country">Pais</label>
          </span>
          {touched.country && errors.country && (
            <small className="p-error">{errors.country}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <Dropdown
              className={`min-w-full ${
                touched.state && errors.state ? "p-invalid" : ""
              }`}
              id="state"
              name="state"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              options={regionOptions}
              placeholder="Selecciona tu provincia/estado"
              filter
              panelFooterTemplate={
                !values.country && (
                  <p className="text-center p-error">
                    Debe seleccionar un país
                  </p>
                )
              }
              disabled={!!!regionOptions || isLoading}
            />
            <label htmlFor="state">Provincia/Estado</label>
          </span>
          {touched.state && errors.state && (
            <small className="p-error">{errors.state}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.city && errors.city ? "p-invalid" : ""
              }`}
              id="city"
              name="city"
              keyfilter={/[A-Za-z ]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              disabled={isLoading}
            />
            <label htmlFor="city">Ciudad</label>
          </span>
          {touched.city && errors.city && (
            <small className="p-error">{errors.city}</small>
          )}
        </div>
        <div className="col-12 md:col-6 my-3">
          <span className="p-float-label">
            <Calendar
              className={`min-w-full ${
                touched.foundationDate && errors.foundationDate
                  ? "p-invalid"
                  : ""
              }`}
              id="foundationDate"
              name="foundationDate"
              dateFormat="dd/mm/yy"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.foundationDate}
              disabled={isLoading}
            />
            <label htmlFor="foundationDate">Fecha de fundación</label>
          </span>
          {touched.foundationDate && errors.foundationDate && (
            <small className="p-error">{yupConstants().required}</small>
          )}
        </div>

        <div className="col-12 md:col-6 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.address && errors.address ? "p-invalid" : ""
              }`}
              id="address"
              name="address"
              keyfilter={/[\w\-/ ]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              disabled={isLoading}
            />
            <label htmlFor="address">Dirección</label>
          </span>
          {touched.address && errors.address && (
            <small className="p-error">{errors.address}</small>
          )}
        </div>
        <div className="min-w-full flex flex-column align-items-center  px-2 mt-3">
          <label className="text-3xl mb-2" htmlFor="description">
            Descripción
          </label>
          <InputTextarea
            className={`min-w-full ${
              touched.description && errors.description ? "p-invalid" : ""
            }`}
            id="description"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            disabled={isLoading}
          />
        </div>

        {/* <FieldSports /> */}
        {/* <div className="col-12 md:col-4 my-3">
            <span className="p-float-label">
              <MultiSelect
                className={`min-w-full md:w-20rem ${
                  touched.sports && errors.sports ? "p-invalid" : ""
                }`}
                id="sports"
                name="sports"
                display="chip"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sports}
                placeholder="Seleccione tipo de doc."
                options={[...sportsTypes]}
                disabled={isLoading}
              />
              <label htmlFor="sports">Deportes</label>
            </span>
            {touched.sports && errors.sports && (
              <small className="p-error">{errors.sports}</small>
            )}
          </div> */}

        <div className="flex w-full justify-content-center mt-2">
          <Button
            type="submit"
            outlined
            rounded
            label="Crear Asociación"
            disabled={isLoading}
            loadingIcon="pi pi-spin pi-spinner"
            loading={isLoading}
            icon="pi pi-check"
          />
        </div>
      </form>
    </div>
  );
};
