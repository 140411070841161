import React from "react";
import { Tooltip } from "primereact/tooltip";

export const CountryBodyTemplate = (rowData: any) => {
  return (
    <div className="flex align-items-center gap-2 w-full">
      <Tooltip target=".flag" mouseTrack mouseTrackLeft={10} />
      <img
        alt="flag"
        src={`https://flagsapi.com/${rowData.countryCode}/shiny/64.png`}
        style={{ width: "22px" }}
        className="flag"
        data-pr-tooltip={rowData.country}
      />
      <span>
        {rowData.state}, {rowData.city}
      </span>
    </div>
  );
};
