import { NewUserForm, UpdateUserForm } from "../../models/forms";
import { NewUSer, User, UserByEmail } from "../../models/user";
import { api } from "./api";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<User[], void>({
      query: () => ({
        url: `users`,
      }),
    }),
    newUser: build.mutation<NewUSer, NewUserForm>({
      query: (data) => ({
        url: "users/create",
        method: "POST",
        body: data,
      }),
    }),
    updateUser: build.mutation<User, UpdateUserForm>({
      query: (data) => ({
        url: "users/update",
        method: "PATCH",
        body: data,
      }),
    }),
    getUserById: build.query<User, string>({
      query: (id) => ({
        url: `users/getUserById/${id}`,
      }),
    }),
    getUserByEmail: build.mutation<UserByEmail, { email: string }>({
      query: (data) => ({
        url: `users/getUserByEmail`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useNewUserMutation,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useGetUserByEmailMutation,
  useUpdateUserMutation,
} = userApi;
