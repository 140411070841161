import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  Panel,
  Dashboard,
  Users,
  NewUser,
  NewAssociations,
  Associations,
  SeeAssociation,
  SeeUser,
  SignOut,
  Profile,
  SportMonthlyFees,
  SportRequests,
  SearchSports,
} from "../pages";
import { PageLayout } from "../layouts/PageLayout/PageLayout";
import AuthGuard from "./AuthGuard";

const RouterApp = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PageLayout />}>
          <Route path="/" element={<Home />} />

          <Route element={<AuthGuard />}>
            <Route path="/panel" element={<Panel />}>
              <Route path="" element={<Dashboard />} />
              <Route path="users" element={<Users />} />
              <Route path="users/new" element={<NewUser />} />
              <Route path="users/:userId" element={<SeeUser />} />
              <Route path="associations" element={<Associations />} />
              <Route path="associations/new" element={<NewAssociations />} />
              <Route
                path="associations/:associationId"
                element={<SeeAssociation />}
              />
              <Route path="sports" element={<SportMonthlyFees />} />
              <Route path="sports/:userId" element={<SportRequests />} />
              <Route path="sports/search" element={<SearchSports />} />
            </Route>

            <Route path="profile" element={<Profile />} />
            <Route path="signout" element={<SignOut />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};
export default RouterApp;
