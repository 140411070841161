import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useSidebarOptios = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const useHandleSidebarState = () => setShowSidebar(!showSidebar);

  const useWacthAndCloseSidebar = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      setShowSidebar(false);
    }, [pathname]);
  };

  return {
    showSidebar,
    useWacthAndCloseSidebar,
    useHandleSidebarState,
  };
};

export default useSidebarOptios;
