import React, { useEffect } from "react";
import { Column } from "primereact/column";
import { DataTable, DataTableRowClickEvent } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/ReduxHooks";
import { useGetUsersQuery } from "../../../store/services/user";
import { actions } from "../../../store/slices/ToastSlice";
import { User } from "../../../models/user";
import {
  ActivatedBodyTemplate,
  CountryBodyFilterTemplate,
  CountryBodyTemplate,
  SexBodyFilterTemplate,
  SexBodyTamplate,
  UserNameBodyTemplate,
  VerifiedRowFilterTemplate,
} from "../../../components/DataTable";

export const Users = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useGetUsersQuery();

  useEffect(() => {
    if (error)
      dispatch(
        actions.showErrorToast({
          title: "Obtener Asociaciones",
          message:
            "Ocurrió un error al intentar obtener todas las asociaciones, por favor intente más tarde.",
        })
      );
  }, [dispatch, error]);

  const handleSelectUser = ({ data }: DataTableRowClickEvent) => {
    navigate(`/panel/users/${data._id}`);
  };

  const idBodyTemplate = (rowData: User) => {
    return (
      <div className="flex align-items-center gap-2 w-full">
        <span>
          {rowData.documentType}: {rowData.document}
        </span>
      </div>
    );
  };

  return (
    <div className="p-card flex w-full  p-3">
      <DataTable
        value={data}
        paginator
        rows={10}
        dataKey="_id"
        loading={isLoading}
        globalFilterFields={["firstName", "country", "email", "activated"]}
        emptyMessage="No se encontraron usuarios."
        onRowClick={handleSelectUser}
        selectionMode="single"
        className=" w-full "
      >
        <Column
          field="firstName"
          header="Nombre"
          filter
          filterPlaceholder="Nombre"
          style={{ minWidth: "250px" }}
          body={UserNameBodyTemplate}
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="email"
          header="Correo"
          filter
          filterPlaceholder="Correo"
          style={{ minWidth: "250px" }}
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          header="País"
          filterField="country"
          style={{ minWidth: "250px" }}
          body={CountryBodyTemplate}
          filterElement={(option) => CountryBodyFilterTemplate(option, data)}
          filter
          filterPlaceholder="País"
          filterMatchMode="equals"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />

        <Column
          header="ID"
          field="document"
          style={{ minWidth: "250px" }}
          body={idBodyTemplate}
          filter
          filterPlaceholder="ID"
          filterMatchMode="startsWith"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          header="Sexo"
          field="sex"
          style={{ maxWidth: "80px" }}
          body={SexBodyTamplate}
          filter
          filterElement={(option) => SexBodyFilterTemplate(option, data)}
          filterMatchMode="equals"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          header="Completo"
          field="complete"
          style={{ minWidth: "20px" }}
          dataType="boolean"
          body={ActivatedBodyTemplate}
          filter
          filterElement={VerifiedRowFilterTemplate}
          filterMatchMode="equals"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
      </DataTable>
    </div>
  );
};
