import {
  IdTypes,
  PayMethods,
  Sexes,
  SportsTypes,
} from "../../models/enums/enums";
import { Sport } from "../../models/sports";

export const idTypes: IdTypes[] = Object.values(IdTypes);

export const sportsTypes: SportsTypes[] = Object.values(SportsTypes);

export const sexTypes: Sexes[] = Object.values(Sexes);

export const emptySport: Sport = {
  name: SportsTypes.NONE,
  description: "",
  price: 0,
  expirated: 10,
};

export const payMethods: PayMethods[] = Object.values(PayMethods);

export const monthsValues = [
  { name: "Enero", value: 0 },
  { name: "Febrero", value: 1 },
  { name: "Marzo", value: 2 },
  { name: "Abril", value: 3 },
  { name: "Mayo", value: 4 },
  { name: "Junio", value: 5 },
  { name: "Julio", value: 6 },
  { name: "Agosto", value: 7 },
  { name: "Septiembre", value: 8 },
  { name: "Octubre", value: 9 },
  { name: "Noviembre", value: 10 },
  { name: "Diciembre", value: 11 },
];

export const yearsValues = [2024, 2025, 2026, 2027, 2028, 2029, 2030];
