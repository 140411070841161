import { Image } from "primereact/image";
import "./Home.scss";

export const Home = () => {

  return (
    <div className="home-container">
      <div className="section">
        <Image
          className="image-run"
          src={"assets/images/run.png"}
          height="400px"
        />
        <div className="first">
          <h1 className="text-3xl text-center md:text-left text-primary-600">
            Hacemos crecer el deporte
          </h1>
          <p className="text-center  md:w-12 md:text-left">
            Acompañamos a los deportistas y asociaciones deportivas que
            participan en distintas actividades.
          </p>
        </div>
        <Image
          className="align-self-center md:hidden"
          src={"assets/images/ciclismo.png"}
          height="200px"
        />
        <div className="second">
          <h2 className="text-2xl text-center md:text-left text-primary-600">
            Registramos tu progreso
          </h2>
          <p className="text-center  md:w-12 md:text-left">
            Seguir tus logros y poder compartirlos es nuestro objetivo
          </p>
        </div>
      </div>
      <div className="flex flex-column">
        <h1 className="text-5xl text-center text-primary-600">Nosotros</h1>
        <p>
          Doing-Sports es un sitio web que buscar generar una mejor
          administracion de informacion para las distintas organizaciones
          deportivas, eventos deportivos y para las personas que practiquen
          alguna actividad. Como asociacion deportiva podras: Informar
          actividades que ofrecen mostrando horarios, profesores, cupos,
          ubicacion, etc. Administrar personas las que tienes a cargo: Hacer
          seguimiento de las cuotas, ver las actividades que practica, eventos
          en los que participo, etc. Crear eventos deportivos: Podras crear y
          administrar eventos deportivos como desees. Como deportista podras:
          Visualizar toda tu informacion personal. Ver a que asociaciones
          deportivas estas vincualdo, hacer seguimiento de tus cuotas y
          actividades. Compartir tus podios, logros y todo lo que desees de los
          eventos en lo que has participado.
        </p>
      </div>
    </div>
  );
};
