import React, { useState } from "react";
import {
  useGetAssociationMonthSPQuery,
  useRemoveMonthlySPMutation,
} from "../../../../store/services/monthly-sport-fee";
import { DataTable } from "primereact/datatable";
import {
  DateBodyTemplate,
  DebtBodyTemplate,
  ExpiratedBodyTemplate,
  PriceBodyTemplate,
  UserNameBodyTemplate,
} from "../../../../components/DataTable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { UserMothlySportFee } from "../../../../models/monthly-sport-fee";
import { PayMonthlySport } from "../../../../components/SmallForms";
import { MonthlyFee } from "../../../../models/enums/enums";
import { useAppDispatch } from "../../../../hooks/ReduxHooks";
import { actions } from "../../../../store/slices/ToastSlice";
import { Dropdown } from "primereact/dropdown";
import {
  monthsValues,
  yearsValues,
} from "../../../../utils/constants/forms-constans";
import { getMonth, getYear } from "../../../../utils/functions/date-helper";
import UserPayments from "./UserPayments";
import { useRemoveSportPaymentMutation } from "../../../../store/services/monthly-sport-payment";

interface MothlyPTableProps {
  associationId: string;
}

export const MothlyPTable = ({ associationId }: MothlyPTableProps) => {
  const dispatch = useAppDispatch();
  const [removeMothlyPayment, setremoveMothlyPayment] =
    useState<UserMothlySportFee>();
  const [payMonthly, setPayMonthly] = useState<UserMothlySportFee>();
  const [removePayMonthly, setRemovePayMonthly] =
    useState<UserMothlySportFee>();
  const [seeUserPayments, setSeeUserPayments] = useState<UserMothlySportFee>();
  const [selectMonth, setSelectMonth] = useState(
    monthsValues.find((month) => month.value === getMonth())?.value ||
      monthsValues[0].value
  );
  const [selectYear, setSelectYear] = useState(getYear());

  const [removeMonthlySP, { isLoading: isLoadingRemoveMSP }] =
    useRemoveMonthlySPMutation();

  const {
    isLoading: isLoadingMonthSQP,
    data,
    isFetching,
    refetch,
  } = useGetAssociationMonthSPQuery({
    associationId,
    month: selectMonth,
    year: selectYear,
  });

  const [removeSportPayment, { isLoading: isLoadingRemoveMSQP }] =
    useRemoveSportPaymentMutation();

  const handleRemovePayPayment = () => {
    const data = {
      month: selectMonth,
      year: selectYear,
      monthlySportFeeId: removePayMonthly?.monthlySportFeeId || "",
    };
    removeSportPayment(data)
      .unwrap()
      .then(() => {
        dispatch(
          actions.showWarnToast({
            title: "Remover pago de cuota",
            message: "¡Pago de cuota removida exitosamente!.",
          })
        );
        refetch();
      })
      .catch(() =>
        dispatch(
          actions.showErrorToast({
            title: "Remover pago de cuota",
            message:
              "Ocurrió un error al intentar remover el pago de la cuota, por favor intente más tarde.",
          })
        )
      );
    setRemovePayMonthly(undefined);
  };

  const handleRemoveMothlyPayment = () => {
    removeMonthlySP(removeMothlyPayment?.monthlySportFeeId || "")
      .unwrap()
      .then(() => {
        dispatch(
          actions.showSuccessToast({
            title: "Remover cuota",
            message: "¡Cuota removida exitosamente!.",
          })
        );
        refetch();
      })
      .catch(() =>
        dispatch(
          actions.showErrorToast({
            title: "Remover cuota",
            message:
              "Ocurrió un error al intentar remover la cuota, por favor intente más tarde.",
          })
        )
      );
    setremoveMothlyPayment(undefined);
  };

  const actionsBodyTemplate = (rowData: UserMothlySportFee) => {
    return (
      <div className="flex gap-2 justify-content-end">
        <Button
          onClick={() => setSeeUserPayments(rowData)}
          severity="info"
          rounded
          outlined
          size="small"
          icon="pi pi-eye"
          tooltip="Ver cuotas"
          loading={isLoadingRemoveMSP}
          tooltipOptions={{ position: "top" }}
        />
        <Button
          onClick={() => setremoveMothlyPayment(rowData)}
          severity="warning"
          rounded
          outlined
          size="small"
          icon="pi pi-user-minus"
          tooltip="Dar de baja"
          loading={isLoadingRemoveMSP}
          tooltipOptions={{ position: "top" }}
        />
        {!(
          rowData.status === MonthlyFee.PAID ||
          rowData.status === MonthlyFee.WITHOUT
        ) && (
          <Button
            onClick={() => setPayMonthly(rowData)}
            severity="success"
            disabled={
              rowData.status === MonthlyFee.PAID ||
              rowData.status === MonthlyFee.WITHOUT
            }
            rounded
            outlined
            size="small"
            icon="pi pi-dollar"
            tooltip="Pagar Cuota"
            tooltipOptions={{ position: "top" }}
          />
        )}
        {rowData.status === MonthlyFee.PAID && (
          <Button
            onClick={() => setRemovePayMonthly(rowData)}
            severity="danger"
            disabled={rowData.status !== MonthlyFee.PAID}
            rounded
            outlined
            size="small"
            icon="pi pi-trash"
            tooltip="Remover Cuota"
            tooltipOptions={{ position: "top" }}
          />
        )}
      </div>
    );
  };

  const header = (
    <div className="flex flex-column sm:flex-row flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Cuotas de usuarios</span>
      <div className="flex flex-column sm:flex-row gap-3">
        <Dropdown
          value={selectYear}
          options={yearsValues}
          onChange={({ value }) => setSelectYear(value)}
        />
        <Dropdown
          value={selectMonth}
          options={monthsValues}
          optionLabel="name"
          onChange={({ value }) => setSelectMonth(value)}
        />

        <Button
          className="align-self-end"
          icon="pi pi-refresh"
          rounded
          raised
          onClick={refetch}
          loading={isLoadingMonthSQP}
        />
      </div>
    </div>
  );

  return (
    <div className="card border-round border-1 surface-border surface-card p-3">
      <DataTable
        value={data}
        loading={isLoadingMonthSQP || isFetching}
        header={header}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        scrollable
        scrollHeight="600px"
        dataKey="MonthlySportsFeesId"
        globalFilterFields={[
          "firstName",
          "sportName",
          "price",
          "debt",
          "expirated",
          "status",
        ]}
        emptyMessage="No se encontraron cuotas."
        className="w-full"
      >
        <Column
          field="firstName"
          header="Nombre"
          filter
          filterPlaceholder="Nombre"
          style={{ minWidth: "200px" }}
          body={UserNameBodyTemplate}
          filterMatchMode="contains"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="sportName"
          header="Deporte"
          filter
          filterPlaceholder="Deporte"
          style={{ minWidth: "200px" }}
          filterMatchMode="startsWith"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="createDate"
          header="Inicio"
          filter
          filterPlaceholder="createDate"
          style={{ minWidth: "150px" }}
          filterMatchMode="startsWith"
          body={DateBodyTemplate}
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="price"
          header="Precio"
          filter
          filterPlaceholder="Precio"
          style={{ minWidth: "100px" }}
          body={PriceBodyTemplate}
          filterMatchMode="startsWith"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="debt"
          header="Deuda"
          filter
          filterPlaceholder="Precio"
          style={{ minWidth: "100px" }}
          body={DebtBodyTemplate}
          filterMatchMode="startsWith"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="expirated"
          header="Vence día"
          filter
          filterPlaceholder="Vencimiento"
          style={{ minWidth: "80px" }}
          align={"center"}
          filterMatchMode="startsWith"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="status"
          header="Estado"
          filter
          filterPlaceholder="Estado"
          style={{ minWidth: "100px" }}
          filterMatchMode="equals"
          body={ExpiratedBodyTemplate}
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          header="Acciones"
          style={{ minWidth: "200px" }}
          filterMatchMode="equals"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
          body={actionsBodyTemplate}
          alignHeader={"right"}
        />
      </DataTable>

      {removeMothlyPayment && (
        <Dialog
          header="Remover cuota"
          visible={!!removeMothlyPayment}
          style={{ width: "40%", minWidth: 300 }}
          onHide={() => setremoveMothlyPayment(undefined)}
          footer={
            <div className="flex justify-content-end gap-3">
              <Button
                label="Quitar"
                onClick={handleRemoveMothlyPayment}
                severity="danger"
              />
              <Button
                label="No"
                onClick={() => setremoveMothlyPayment(undefined)}
                severity="info"
              />
            </div>
          }
        >
          <p className="m-0">
            ¿Estas seguro que deseas quitar la cuota de{" "}
            <b>
              {removeMothlyPayment.lastName}, {removeMothlyPayment.firstName}
            </b>{" "}
            en <b>{removeMothlyPayment.sportName}</b>?
          </p>
        </Dialog>
      )}
      {payMonthly && (
        <Dialog
          header={`Cuota: ${payMonthly.lastName}, ${payMonthly.firstName}`}
          visible={!!payMonthly}
          style={{ width: "20%", minWidth: 300 }}
          onHide={() => setPayMonthly(undefined)}
        >
          <PayMonthlySport
            fee={payMonthly}
            selectMonth={selectMonth}
            selectYear={selectYear}
            handleClose={() => setPayMonthly(undefined)}
            reload={refetch}
          />
        </Dialog>
      )}
      {seeUserPayments && (
        <Dialog
          header={`Cuotas: ${seeUserPayments.lastName}, ${seeUserPayments.firstName}`}
          visible={!!seeUserPayments}
          style={{ width: "80%", minWidth: 300, maxWidth: 800, height: 600 }}
          onHide={() => setSeeUserPayments(undefined)}
        >
          <UserPayments userId={seeUserPayments.userId} />
        </Dialog>
      )}
      {removePayMonthly && (
        <Dialog
          header={`Remover cuota`}
          visible={!!removePayMonthly}
          style={{ width: "40%", minWidth: 300 }}
          onHide={() => setRemovePayMonthly(undefined)}
          footer={
            <div className="flex justify-content-end gap-3">
              <Button
                label="Quitar"
                onClick={handleRemovePayPayment}
                severity="danger"
                loading={isLoadingRemoveMSQP}
              />
              <Button
                label="No"
                onClick={() => setRemovePayMonthly(undefined)}
                severity="info"
                loading={isLoadingRemoveMSQP}
              />
            </div>
          }
        >
          <p className="m-0">
            <h3 className="text-red-700">
              ¡Advertencia! removera el pago total de la cuota{" "}
              <i className="pi pi-exclamation-triangle"></i>
            </h3>
            ¿Estas seguro que deseas quitar el pago de la cuota de{" "}
            <b>
              {removePayMonthly.lastName}, {removePayMonthly.firstName}
            </b>{" "}
            en <b>{removePayMonthly.sportName}</b>?
          </p>
        </Dialog>
      )}
    </div>
  );
};
