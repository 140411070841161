/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useAppDispatch } from "../../../hooks/ReduxHooks";
import { useNavigate } from "react-router-dom";
import { useNewUserMutation } from "../../../store/services/user";
import { yupConstants } from "../../../utils/constants/yup-constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NewUserForm } from "../../../models/forms";
import { actions } from "../../../store/slices/ToastSlice";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import countriesAndRegions from "../../../utils/jsons/countriesAndRegions.json";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { idTypes, sexTypes } from "../../../utils/constants/forms-constans";
export const NewUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [newUSer, { isLoading }] = useNewUserMutation();

  const handleNewUser = (values: NewUserForm) => {
    newUSer(values)
      .unwrap()
      .then(() => {
        resetForm();
        dispatch(
          actions.showSuccessToast({
            title: "Registrarse",
            message: "¡Usuario registrado exitosamente!.",
          })
        );
        navigate("/panel/users");
      })
      .catch(() =>
        dispatch(
          actions.showErrorToast({
            title: "Registrarse",
            message:
              "Ocurrió un error al intentar registrarse, por favor intente más tarde.",
          })
        )
      );
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      birthDate: "",
      documentType: "",
      document: "",
      sex: "",
      country: "",
      countryCode: "",
      state: "",
      city: "",
      address: "",
      phone: "",
      emergencyPhone: "",
      emergencyContact: "",
      postCode: "",
    } as NewUserForm,
    validationSchema: Yup.object({
      email: Yup.string()
        .email(yupConstants().email)
        .required(yupConstants().required),
      firstName: Yup.string().required(yupConstants().required),
      lastName: Yup.string().required(yupConstants().required),
      birthDate: Yup.date().required(yupConstants().required),
      documentType: Yup.string().required(yupConstants().required),
      document: Yup.string().required(yupConstants().required),
      sex: Yup.string().required(yupConstants().required),
      country: Yup.string().required(yupConstants().required),
      state: Yup.string().required(yupConstants().required),
      city: Yup.string().required(yupConstants().required),
      address: Yup.string().required(yupConstants().required),
      postCode: Yup.string().required(yupConstants().required),
      emergencyContact: Yup.string().required(yupConstants().required),
      emergencyPhone: Yup.number().required(yupConstants().required),
      phone: Yup.number().required(yupConstants().required),
    }),
    onSubmit: handleNewUser,
  });

  const regionOptions = useMemo(() => {
    if (values.country) {
      const result = countriesAndRegions.find(
        (country) => country.countryName === values.country
      );
      setFieldValue("countryCode", result?.countryShortCode);

      return result?.regions.map((region) => region.name);
    }
    setFieldValue("countryCode", "");
    return [];
  }, [values.country]);

  return (
    <div className="p-card flex flex-column p-3">
      <h1>Crear usuario</h1>
      <form className="grid " onSubmit={handleSubmit}>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.email && errors.email ? "p-invalid" : ""
              }`}
              id="email"
              name="email"
              keyfilter="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isLoading}
            />
            <label htmlFor="email">Correo</label>
          </span>
          {touched.email && errors.email && (
            <small className="p-error">{errors.email}</small>
          )}
        </div>

        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.firstName && errors.firstName ? "p-invalid" : ""
              }`}
              id="firstName"
              name="firstName"
              keyfilter={/[A-Za-z ]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              disabled={isLoading}
            />
            <label htmlFor="firstName">Apellido</label>
          </span>
          {touched.firstName && errors.firstName && (
            <small className="p-error">{errors.firstName}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.lastName && errors.lastName ? "p-invalid" : ""
              }`}
              id="lastName"
              name="lastName"
              keyfilter={/[A-Za-z ]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              disabled={isLoading}
            />
            <label htmlFor="lastName">Nombre completo</label>
          </span>
          {touched.lastName && errors.lastName && (
            <small className="p-error">{errors.lastName}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <Calendar
              className={`min-w-full ${
                touched.birthDate && errors.birthDate ? "p-invalid" : ""
              }`}
              id="birthDate"
              name="birthDate"
              dateFormat="dd/mm/yy"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.birthDate}
              disabled={isLoading}
            />
            <label htmlFor="birthDate">Fecha de nacimiento</label>
          </span>
          {touched.birthDate && errors.birthDate && (
            <small className="p-error">{yupConstants().required}</small>
          )}
        </div>

        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <Dropdown
              className={`min-w-full ${
                touched.documentType && errors.documentType ? "p-invalid" : ""
              }`}
              id="documentType"
              name="documentType"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.documentType}
              placeholder="Seleccione tipo de doc."
              options={[...idTypes]}
              disabled={isLoading}
            />
            <label htmlFor="documentType">Tipo de documento</label>
          </span>
          {touched.documentType && errors.documentType && (
            <small className="p-error">{errors.documentType}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.document && errors.document ? "p-invalid" : ""
              }`}
              id="document"
              name="document"
              keyfilter="int"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.document}
              disabled={isLoading}
            />
            <label htmlFor="document">Documento</label>
          </span>
          {touched.document && errors.document && (
            <small className="p-error">{errors.document}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <Dropdown
              className={`min-w-full ${
                touched.sex && errors.sex ? "p-invalid" : ""
              }`}
              id="sex"
              name="sex"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.sex}
              placeholder="Seleccione su sexo"
              options={sexTypes}
              disabled={isLoading}
            />
            <label htmlFor="sex">Sexo</label>
          </span>
          {touched.sex && errors.sex && (
            <small className="p-error">{errors.sex}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <Dropdown
              className={`min-w-full ${
                touched.country && errors.country ? "p-invalid" : ""
              }`}
              id="country"
              name="country"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              options={countriesAndRegions.map(
                (countries) => countries.countryName
              )}
              placeholder="Selecciona tu país"
              filter
              disabled={isLoading}
            />
            <label htmlFor="country">Pais</label>
          </span>
          {touched.country && errors.country && (
            <small className="p-error">{errors.country}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <Dropdown
              className={`min-w-full ${
                touched.state && errors.state ? "p-invalid" : ""
              }`}
              id="state"
              name="state"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              options={regionOptions}
              placeholder="Selecciona tu provincia/estado"
              filter
              panelFooterTemplate={
                !values.country && (
                  <p className="text-center p-error">
                    Debe seleccionar un país
                  </p>
                )
              }
              disabled={!!!regionOptions || isLoading}
            />
            <label htmlFor="state">Provincia/Estado</label>
          </span>
          {touched.state && errors.state && (
            <small className="p-error">{errors.state}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.city && errors.city ? "p-invalid" : ""
              }`}
              id="city"
              name="city"
              keyfilter={/[A-Za-z ]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              disabled={isLoading}
            />
            <label htmlFor="city">Ciudad</label>
          </span>
          {touched.city && errors.city && (
            <small className="p-error">{errors.city}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.address && errors.address ? "p-invalid" : ""
              }`}
              id="address"
              name="address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              disabled={isLoading}
            />
            <label htmlFor="address">Dirección</label>
          </span>
          {touched.address && errors.address && (
            <small className="p-error">{errors.address}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.postCode && errors.postCode ? "p-invalid" : ""
              }`}
              id="postCode"
              name="postCode"
              keyfilter={/[A-Za-z ]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.postCode}
              disabled={isLoading}
            />
            <label htmlFor="postCode">Código postal</label>
          </span>
          {touched.postCode && errors.postCode && (
            <small className="p-error">{errors.postCode}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.phone && errors.phone ? "p-invalid" : ""
              }`}
              id="phone"
              name="phone"
              keyfilter={"int"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
            />
            <label htmlFor="phone">Celular</label>
          </span>
          {touched.phone && errors.phone && (
            <small className="p-error">{errors.phone}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.emergencyContact && errors.emergencyContact
                  ? "p-invalid"
                  : ""
              }`}
              id="emergencyContact"
              name="emergencyContact"
              keyfilter={/[A-Za-z ]/}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.emergencyContact}
              disabled={isLoading}
            />
            <label htmlFor="emergencyContact">
              Nombre contacto de emergencia
            </label>
          </span>
          {touched.emergencyContact && errors.emergencyContact && (
            <small className="p-error">{errors.emergencyContact}</small>
          )}
        </div>
        <div className="col-12 md:col-4 my-3">
          <span className="p-float-label">
            <InputText
              className={`min-w-full ${
                touched.phone && errors.emergencyPhone ? "p-invalid" : ""
              }`}
              id="emergencyPhone"
              name="emergencyPhone"
              onChange={handleChange}
              onBlur={handleBlur}
              keyfilter={"int"}
              value={values.emergencyPhone}
            />
            <label htmlFor="emergencyPhone">Celular de emergencia</label>
          </span>
          {touched.emergencyPhone && errors.emergencyPhone && (
            <small className="p-error">{errors.emergencyPhone}</small>
          )}
        </div>
        <div className="flex w-full justify-content-center mt-2">
          <Button
            type="submit"
            outlined
            rounded
            label="Crear usuario"
            disabled={isLoading}
            loadingIcon="pi pi-spin pi-spinner"
            loading={isLoading}
            icon="pi pi-check"
          />
        </div>
      </form>
    </div>
  );
};
