import React from "react";

export const ActivatedBodyTemplate = (rowData: any) => {
  return (
    <i
      className={`pi ${
        rowData.activated || rowData.complete
          ? "true-icon pi-check-circle text-green-500"
          : "false-icon pi-times-circle text-red-500"
      }`}
    ></i>
  );
};
