import React, { useState } from "react";
import { useGetUserPaymentsQuery } from "../../../../store/services/monthly-sport-payment";
import { getYear } from "../../../../utils/functions/date-helper";
import { Dropdown } from "primereact/dropdown";
import { yearsValues } from "../../../../utils/constants/forms-constans";
import { Accordion, AccordionTab } from "primereact/accordion";
import { convertToPrice } from "../../../../utils/functions/currencies";
import { Skeleton } from "primereact/skeleton";

interface UserPaymentsProps {
  userId: string;
}

const UserPayments = ({ userId }: UserPaymentsProps) => {
  const [selectYear, setSelectYear] = useState<number>(getYear());
  const { data, isLoading } = useGetUserPaymentsQuery({
    userId: userId,
    year: selectYear || getYear(),
  });
  const handleSelectYear = ({ value }: { value: number }) => {
    setSelectYear(value);
  };

  if (isLoading) {
    <div className="flex flex-column align-items-center gap-2">
      <Skeleton shape="circle" size="5rem"></Skeleton>
      <Skeleton width="10rem" className="mb-2"></Skeleton>
      <Skeleton width="8rem" className="mb-2"></Skeleton>
      <Skeleton width="30rem" height="4rem" className="mt-6"></Skeleton>
    </div>;
  }
  return (
    <div>
      <div className="flex gap-3 align-items-center mb-3">
        <h3>Seleccionar año del resumen: </h3>
        <Dropdown
          value={selectYear}
          options={yearsValues}
          onChange={handleSelectYear}
        />
      </div>

      <Accordion multiple>
        {data &&
          !!data.length &&
          data.map(
            (item) =>
              !!item.payments.length && (
                <AccordionTab header={item.name} key={item.name}>
                  {item.payments.map(
                    ({
                      amount,
                      createDate,
                      method,
                      sportExpirated,
                      sportName,
                    }) => (
                      <div
                        className="border-1 border-round p-2 mt-2 "
                        key={createDate}
                      >
                        <p className="text-center">
                          <b>Deporte: </b> {sportName}
                        </p>

                        <div className="md:flex justify-content-between">
                          <p>
                            <b>Pagó:</b> {convertToPrice(amount)}
                          </p>
                          <p>
                            <b>Día:</b> {createDate.split(",")[0]}
                          </p>
                        </div>

                        <div className="md:flex justify-content-between">
                          <p>
                            <b>Método:</b> {method}
                          </p>
                          <p>
                            <b>Expiraba el día:</b> {sportExpirated}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </AccordionTab>
              )
          )}
      </Accordion>

      {(!data || !data.length) && (
        <h3 className="text-center mt-8">No se han encontrado cuotas</h3>
      )}
    </div>
  );
};

export default UserPayments;
