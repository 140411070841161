import {
  CreateMultipleMonthlySportsFees,
  CreateNewUserMP,
  MonthPaymentQuery,
  UserMothlySportFee,
  SportReq,
} from "../../models/monthly-sport-fee";
import { api } from "./api";

export const monthlySportsPaynmentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createMonthlySP: build.mutation<void, CreateNewUserMP>({
      query: (data) => ({
        url: `monthly-sports-fees/create`,
        method: "POST",
        body: data,
      }),
    }),
    createMultipleMonthlySP: build.mutation<
      void,
      CreateMultipleMonthlySportsFees
    >({
      query: (data) => ({
        url: `monthly-sports-fees/createMultiple`,
        method: "POST",
        body: data,
      }),
    }),
    getUserMonthlySP: build.query<UserMothlySportFee[], string>({
      query: (id) => ({
        url: `monthly-sports-fees/getUserMonthlySportsFees/${id}`,
      }),
    }),
    getAssociationMonthSP: build.query<UserMothlySportFee[], MonthPaymentQuery>(
      {
        query: (data) => ({
          method: "POST",
          url: `monthly-sports-fees/getAssociationMonthlySportsFees/`,
          params: data,
        }),
      }
    ),
    removeMonthlySP: build.mutation<UserMothlySportFee[], string>({
      query: (id) => ({
        method: "DELETE",
        url: `monthly-sports-fees/remove/${id}`,
      }),
    }),
    getUserReq: build.query<SportReq[], string>({
      query: (id) => ({
        url: `monthly-sports-fees/getUserReq/${id}`,
      }),
    }),
    getAssociationReq: build.query<SportReq[], string>({
      query: (id) => ({
        url: `monthly-sports-fees/getAssociationReq/${id}`,
      }),
    }),
    acceptReq: build.mutation<void, string>({
      query: (id) => ({
        url: `monthly-sports-fees/acceptReq/${id}`,
      }),
    }),
  }),
});

export const {
  useCreateMultipleMonthlySPMutation,
  useGetUserMonthlySPQuery,
  useRemoveMonthlySPMutation,
  useGetAssociationMonthSPQuery,
  useCreateMonthlySPMutation,
  useGetUserReqQuery,
  useGetAssociationReqQuery,
  useAcceptReqMutation,
} = monthlySportsPaynmentsApi;
