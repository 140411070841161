import React from "react";
import { SportReq } from "../../models/monthly-sport-fee";
import { Button } from "primereact/button";
import { convertToPrice } from "../../utils/functions/currencies";
import { SpinnerPage } from "../SpinnerPage/SpinnerPage";
import { Accordion, AccordionTab } from "primereact/accordion";
import { CountryBodyTemplate } from "../DataTable";
import {
  useAcceptReqMutation,
  useRemoveMonthlySPMutation,
} from "../../store/services/monthly-sport-fee";
import { actions } from "../../store/slices/ToastSlice";
import { useAppDispatch } from "../../hooks/ReduxHooks";

interface ReqTabsPoprs {
  data: SportReq[];
  isLoading: boolean;
  mode: "user" | "association";
  refetch: () => void;
}
const ReqTabs = ({ data, isLoading, mode, refetch }: ReqTabsPoprs) => {
  const dispatch = useAppDispatch();
  const [acceptReq, { isLoading: isLoadingAccept }] = useAcceptReqMutation();

  const [removeMonthlySP, { isLoading: isLoadingRemoveMSP }] =
    useRemoveMonthlySPMutation();

  const handleReject = (id: string) => {
    removeMonthlySP(id)
      .unwrap()
      .then(() => {
        dispatch(
          actions.showSuccessToast({
            title: "Rechazar solicitúd",
            message: "Solicitúd rechazada exitosamente!.",
          })
        );
        refetch();
      })
      .catch(() =>
        dispatch(
          actions.showErrorToast({
            title: "Rechazar solicitúd",
            message:
              "Ocurrió un error al intentar rechazar solicitúd, por favor intente más tarde.",
          })
        )
      );
  };

  const handleAccept = (id: string) => {
    acceptReq(id)
      .unwrap()
      .then(() => {
        dispatch(
          actions.showSuccessToast({
            title: "Aceptar solicitúd",
            message: "Solicitúd aceptada exitosamente!.",
          })
        );
        refetch();
      })
      .catch(() =>
        dispatch(
          actions.showErrorToast({
            title: "Aceptar solicitúd",
            message:
              "Ocurrió un error al intentar aceptar solicitúd, por favor intente más tarde.",
          })
        )
      );
  };

  const send: SportReq[] = data?.filter((item) => item.userAcceptance) || [];
  const recieve: SportReq[] =
    data?.filter((item) => item.associationAcceptance) || [];
  return isLoading ? (
    <div className="mt-4">
      <h1 className="text-center">Obteniendo información...</h1>
      <SpinnerPage />
    </div>
  ) : (
    <Accordion multiple activeIndex={mode === "user" ? [0, 1] : undefined}>
      <AccordionTab
        header={
          mode === "user" ? "Solicitudes envíadas" : "Solicitudes recibidas"
        }
      >
        {!send.length ? (
          <h1 className="text-center">Sin datos</h1>
        ) : (
          send.map((item) => (
            <div
              key={item.createDate.toString()}
              className="justify-content-center align-content-center border-round border-1 surface-border surface-card py-2 px-4 my-2"
            >
              <h2 className="text-center underline m-0 ">{item.name}</h2>
              <div className="flex  justify-content-center gap-2">
                <i
                  data-pr-tooltip="Ubicación"
                  className="pi pi-map-marker text-red-700"
                />
                {CountryBodyTemplate(item)}
              </div>
              <div className="flex justify-content-between mt-2">
                <p className="m-0">{item.reqName}</p>
                <p className="m-0">
                  Envíada: <b>{item.createDate}</b>
                </p>
              </div>
              <div className="flex justify-content-between mt-2">
                <p className="m-0">
                  Precio: <b>{convertToPrice(item.price)}</b>
                </p>
                <p className="m-0">
                  Vencimiento: <b>{item.expirated}</b>
                </p>
              </div>
              {mode === "association" && (
                <div className="flex justify-content-center gap-4">
                  <Button
                    icon="pi pi-check"
                    label="Aceptar solicitúd"
                    loading={isLoading || isLoadingAccept || isLoadingRemoveMSP}
                    onClick={() => handleAccept(item._id)}
                  />

                  <Button
                    icon="pi pi-times"
                    severity="secondary"
                    label="Rechazar solicitúd"
                    loading={isLoading || isLoadingAccept || isLoadingRemoveMSP}
                    onClick={() => handleReject(item._id)}
                  />
                </div>
              )}
            </div>
          ))
        )}
      </AccordionTab>
      <AccordionTab
        header={
          mode === "user" ? "Solicitudes recibidas" : "Solicitudes envíadas"
        }
      >
        {!recieve.length ? (
          <h1 className="text-center">Sin datos</h1>
        ) : (
          recieve.map((item) => (
            <div
              key={item.createDate.toString()}
              className="justify-content-center align-content-center border-round border-1 surface-border surface-card py-2 px-4 my-2"
            >
              <h2 className="text-center underline m-0 ">{item.name}</h2>
              <div className="flex  justify-content-center gap-2">
                <i
                  data-pr-tooltip="Ubicación"
                  className="pi pi-map-marker text-red-700"
                />
                {CountryBodyTemplate(item)}
              </div>
              <div className="flex justify-content-between mt-2">
                <p className="m-0">{item.reqName}</p>
                <p className="m-0">
                  Recibida: <b>{item.createDate}</b>
                </p>
              </div>
              <div className="flex justify-content-between mt-2">
                <p className="m-0">
                  Precio: <b>{convertToPrice(item.price)}</b>
                </p>
                <p className="m-0">
                  Vencimiento: <b>{item.expirated}</b>
                </p>
              </div>
              {mode === "user" && (
                <div className="flex justify-content-center gap-4">
                  <Button
                    icon="pi pi-check"
                    label="Aceptar solicitúd"
                    loading={isLoading || isLoadingAccept || isLoadingRemoveMSP}
                    onClick={() => handleAccept(item._id)}
                  />
                  <Button
                    icon="pi pi-times"
                    severity="secondary"
                    label="Rechazar solicitúd"
                    loading={isLoading || isLoadingAccept || isLoadingRemoveMSP}
                    onClick={() => handleReject(item._id)}
                  />
                </div>
              )}
            </div>
          ))
        )}
      </AccordionTab>
    </Accordion>
  );
};

export default ReqTabs;
