import React from "react";
import { Formik, Form, FieldArray, Field, FieldProps } from "formik";
import * as Yup from "yup";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { yupConstants } from "../../utils/constants/yup-constants";
import { useGetUserByEmailMutation } from "../../store/services/user";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { actions } from "../../store/slices/ToastSlice";
import { MultiSelect } from "primereact/multiselect";
import { CreateMultipleMonthlySportsFees } from "../../models/monthly-sport-fee";
import { useParams } from "react-router-dom";
import { useCreateMultipleMonthlySPMutation } from "../../store/services/monthly-sport-fee";

type SportsToSelect = {
  id: string;
  name: string;
};
interface FieldAddUserProps {
  refetch: () => void;
  handleClose: () => void;
  sportsToSelect: SportsToSelect[];
}

export const FieldAddUser = ({
  handleClose,
  refetch,
  sportsToSelect,
}: FieldAddUserProps) => {
  const dispatch = useAppDispatch();
  const { associationId } = useParams();

  const [
    createMultipleMonthlySP,
    { isLoading: isLoadingCreateMultiplePayments },
  ] = useCreateMultipleMonthlySPMutation();

  const emptyUserSport = {
    email: "",
    name: "",
    userId: "",
    sports: [] as unknown as SportsToSelect[],
  };

  const [
    getUserByEmail,
    { isLoading: isLoadingFindUser, reset: resetFindUser },
  ] = useGetUserByEmailMutation();

  const handleFindUserByEmail = (
    email: string,
    setFieldValue: any,
    i: number
  ) => {
    getUserByEmail({ email })
      .unwrap()
      .then((data) => {
        setFieldValue(`users.${i}.name`, `${data.lastName}, ${data.firstName}`);
        setFieldValue(`users.${i}.userId`, data._id);
      })
      .catch(() => {
        setFieldValue(`users.${i}.name`, "");

        dispatch(
          actions.showErrorToast({
            title: "Buscar usuario",
            message:
              "Ocurrió un error al intentar buscar el usuario, por favor revise el correo.",
          })
        );
      });
  };

  return (
    <Formik
      initialValues={{
        users: [emptyUserSport],
      }}
      validationSchema={Yup.object().shape({
        users: Yup.array().of(
          Yup.object().shape({
            email: Yup.string().required(yupConstants().required),
            name: Yup.string().required(yupConstants().required),
            userId: Yup.string().required(yupConstants().required),
            sports: Yup.array().of(
              Yup.object().shape({
                id: Yup.string().required(yupConstants().required),
                name: Yup.string().required(yupConstants().required),
              })
            ),
          })
        ),
      })}
      onSubmit={(values) => {
        const dataFormat: CreateMultipleMonthlySportsFees = {
          associationId: associationId || "",
          users: values.users.map(({ userId, sports }) => ({
            userId,
            associationAcceptance: true,
            sportsIds: [...sports.map(({ id }) => id)],
          })),
        };

        createMultipleMonthlySP(dataFormat)
          .unwrap()
          .then(() => {
            dispatch(
              actions.showSuccessToast({
                title: "Crear cuotas de usuarios",
                message: "Cuotas cargadas exitosamente.",
              })
            );
            refetch();
            handleClose();
          })
          .catch(() =>
            dispatch(
              actions.showErrorToast({
                title: "Crear cuotas de usuarios",
                message:
                  "Ocurrió un error al intentar crear cuotas de usuarios, por favor intente mas tarde.",
              })
            )
          );
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setSubmitting,
        isSubmitting,
      }) => (
        <Form className="w-full my-2" onSubmit={handleSubmit}>
          <FieldArray name="users">
            {({ remove, push }) => (
              <Fieldset
                className="w-full"
                legend={
                  <div className="flex align-items-center gap-2">
                    <span className="font-bold">Usuarios</span>
                    <Button
                      size="small"
                      rounded
                      text
                      onClick={() => {
                        resetFindUser();
                        push(emptyUserSport);
                      }}
                    >
                      <i className="pi pi-plus"></i>
                    </Button>
                  </div>
                }
              >
                <>
                  {values.users.length > 0 ? (
                    <>
                      {values.users.map((user, index) => (
                        <div
                          className="grid shadow-2 border-round border-1 surface-border surface-card  mb-4"
                          key={index}
                        >
                          <Field
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`users.${index}.email`}
                          >
                            {({ field, meta, form }: FieldProps) => (
                              <>
                                <div className="col-9 lg:col-6 my-1 flex flex-column gap-2">
                                  <label
                                    className="text-lg"
                                    htmlFor={`users.${index}.email`}
                                  >
                                    Email
                                  </label>
                                  <input
                                    {...field}
                                    className={`p-inputtext p-component p-filled ${
                                      meta.touched && meta.error
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    type="text"
                                    disabled={
                                      isLoadingFindUser ||
                                      isLoadingCreateMultiplePayments
                                    }
                                  />
                                  {meta.touched && meta.error && (
                                    <small className="p-error">
                                      {meta.error}
                                    </small>
                                  )}
                                </div>
                                <Button
                                  className="col-1 justify-content-center"
                                  severity="warning"
                                  size="small"
                                  style={{ height: 45, marginTop: 45 }}
                                  loading={
                                    isLoadingFindUser ||
                                    isLoadingCreateMultiplePayments
                                  }
                                  onClick={() => {
                                    setSubmitting(true);
                                    handleFindUserByEmail(
                                      meta.value,
                                      form.setFieldValue,
                                      index
                                    );
                                  }}
                                >
                                  <i className="pi pi-search"></i>
                                </Button>
                              </>
                            )}
                          </Field>
                          <Field
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`users.${index}.sports`}
                          >
                            {({ meta, form }: FieldProps) =>
                              values.users[index].name && (
                                <>
                                  <div className="col-12 p-0 pl-3 m-0">
                                    <h3>{values.users[index].name}</h3>
                                  </div>
                                  <div className="col-9 lg:col-6 my-1 flex flex-column gap-2">
                                    <label
                                      className="text-lg"
                                      htmlFor={`users.${index}.email`}
                                    >
                                      Seleccionar deportes
                                    </label>
                                    <MultiSelect
                                      className={
                                        meta.value.length === 0 || meta.error
                                          ? "p-invalid"
                                          : ""
                                      }
                                      filter
                                      display="chip"
                                      value={meta.value}
                                      options={[...sportsToSelect]}
                                      optionLabel="name"
                                      onChange={(e) => {
                                        form.setFieldValue(
                                          `users.${index}.sports`,
                                          e.value
                                        );
                                        setSubmitting(false);
                                        if (e.value.length === 0) {
                                          form.setFieldError(
                                            `users.${index}.sports`,
                                            "Necesita seleccionar al menos un deporte"
                                          );
                                          setSubmitting(true);
                                        }
                                      }}
                                    />
                                    {(meta.value.length === 0 ||
                                      meta.error) && (
                                      <small className="p-error">
                                        Necesita seleccionar al menos un deporte
                                      </small>
                                    )}
                                  </div>
                                </>
                              )
                            }
                          </Field>

                          <div className="col-12 h-7rem flex flex-row-reverse">
                            <Button
                              className="mt-4"
                              onClick={() => remove(index)}
                              severity="danger"
                              text
                            >
                              <i className="pi pi-trash text-3xl"></i>
                            </Button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-content-center gap-2">
                        <Button
                          className="md:hidden"
                          icon="pi pi-check"
                          type="submit"
                          severity="success"
                          loading={isLoadingCreateMultiplePayments}
                        />
                        <Button
                          className="hidden md:block"
                          icon="pi pi-check"
                          label="Agregar"
                          type="submit"
                          disabled={isSubmitting}
                          severity="success"
                          loading={isLoadingCreateMultiplePayments}
                        />
                        <Button
                          className="hidden md:block"
                          icon="pi pi-times"
                          label="Cancelar"
                          type="button"
                          severity="secondary"
                          onClick={handleClose}
                          loading={isLoadingCreateMultiplePayments}
                        />
                        <Button
                          className="md:hidden"
                          icon="pi pi-times"
                          type="button"
                          severity="secondary"
                          onClick={handleClose}
                          loading={isLoadingCreateMultiplePayments}
                        />
                      </div>
                    </>
                  ) : (
                    <h2 className="text-center">
                      Presione el botón "+" para agregar un usuario
                    </h2>
                  )}
                </>
              </Fieldset>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};
