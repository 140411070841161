import { Button } from "primereact/button";
import React from "react";
import { NavLink } from "react-router-dom";
import { useNavePagesItems } from "../../../hooks/useNavPagesItems";

const PagesNav = () => {
  const navPagesItems = useNavePagesItems();
  return (
    <>
      {navPagesItems.map((item) => (
        <NavLink to={item.url} key={item.url}>
          {({ isActive }) => (
            <Button
              className={isActive ? "secondary" : ""}
              label={item.name}
              icon={item.icon}
              text
            />
          )}
        </NavLink>
      ))}
    </>
  );
};

export default PagesNav;
