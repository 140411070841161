import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ShowToastAction, toastStateProps } from "../../models/toast";
import { ToasterTypes } from "../../models/enums/enums";

const initialState: toastStateProps = {
  title: "",
  message: "",
  type: ToasterTypes.SUCCESS,
  show: false,
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    // showToast: (state, actions: PayloadAction<ShowToastAction>) => {
    //   const { message, type, title } = actions.payload;
    //   state.title = title;
    //   state.message = message;
    //   state.type = type;
    //   state.show = true;
    // },
    showSuccessToast: (state, actions: PayloadAction<ShowToastAction>) => {
      const { message, title } = actions.payload;
      state.title = title;
      state.message = message;
      state.type = ToasterTypes.SUCCESS;
      state.show = true;
    },
    showErrorToast: (state, actions: PayloadAction<ShowToastAction>) => {
      const { message, title } = actions.payload;
      state.title = title;
      state.message = message;
      state.type = ToasterTypes.ERROR;
      state.show = true;
    },
    showWarnToast: (state, actions: PayloadAction<ShowToastAction>) => {
      const { message, title } = actions.payload;
      state.title = title;
      state.message = message;
      state.type = ToasterTypes.WARN;
      state.show = true;
    },
    showInfoToast: (state, actions: PayloadAction<ShowToastAction>) => {
      const { message, title } = actions.payload;
      state.title = title;
      state.message = message;
      state.type = ToasterTypes.INFO;
      state.show = true;
    },
    hideToast: (state) => {
      state.message = "";
      state.show = false;
    },
  },
});

export const { actions } = toastSlice;
export default toastSlice.reducer;
