/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from "react";
import { SpinnerPage } from "../../../components/SpinnerPage/SpinnerPage";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/ReduxHooks";
import { actions } from "../../../store/slices/AuthSlice";

export const SignOut = () => {
  const { signOut } = useAuthenticator();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(actions.signOut());
    signOut();
  }, []);

  return (
    <div className="flex justify-content-center align-self-center mt-8">
      <SpinnerPage />
      <Navigate to={"/"} />
    </div>
  );
};
