/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useGetAssociationsQuery } from "../../../store/services/association";
import { useAppDispatch } from "../../../hooks/ReduxHooks";
import { actions } from "../../../store/slices/ToastSlice";
import { DataTable, DataTableRowClickEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import {
  ActivatedBodyTemplate,
  CountryBodyTemplate,
  AssociationNameBodyTemplate,
} from "../../../components/DataTable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Button } from "primereact/button";
import countriesAndRegions from "../../../utils/jsons/countriesAndRegions.json";
import { InputText } from "primereact/inputtext";

export const Associations = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const { isLoading, data, error } = useGetAssociationsQuery({
    email,
    name,
    country,
    province,
    city,
  });
  useEffect(() => {
    if (error)
      dispatch(
        actions.showErrorToast({
          title: "Obtener Asociaciones",
          message:
            "Ocurrió un error al intentar obtener todas las asociaciones, por favor intente más tarde.",
        })
      );
  }, [error]);

  const handleSelectAssociations = ({ data }: DataTableRowClickEvent) => {
    navigate(`/panel/associations/${data._id}`);
  };

  const regionOptions = useMemo(() => {
    if (country) {
      const result = countriesAndRegions.find(
        (item) => item.countryName === country
      );

      return result?.regions.map((region) => region.name);
    }
    return [];
  }, [country]);

  const handleCountry = ({ value }: DropdownChangeEvent) => setCountry(value);
  const handleProvince = ({ value }: DropdownChangeEvent) => setProvince(value);

  const header = () => {
    return (
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-4 p-inputgroup mt-4 ">
          <span className="p-float-label">
            <Dropdown
              className="w-full"
              id="country"
              name="country"
              value={country}
              onChange={handleCountry}
              filter
              placeholder="Selecciona tu país"
              options={countriesAndRegions.map(
                (countries) => countries.countryName
              )}
              disabled={isLoading}
            />
            <label htmlFor="country">País</label>
          </span>
          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              setCountry("");
              setProvince("");
              setCity("");
            }}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4 p-inputgroup mt-4 ">
          <span className="p-float-label">
            <Dropdown
              className="w-full"
              id="province"
              name="province"
              value={province}
              onChange={handleProvince}
              filter
              options={regionOptions}
              panelFooterTemplate={
                !country && (
                  <p className="text-center p-error">
                    Debe seleccionar un país
                  </p>
                )
              }
              disabled={isLoading}
            />
            <label htmlFor="province">Provincia</label>
          </span>
          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              setProvince("");
              setCity("");
            }}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4 p-inputgroup mt-4 ">
          <span className="p-float-label">
            <InputText
              className="w-full"
              id="city"
              name="city"
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
              disabled={isLoading}
            />
            <label htmlFor="city">Ciudad</label>
          </span>

          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => setCity("")}
          />
        </div>
        <div className="col-12 md:col-6  p-inputgroup mt-4">
          <span className="p-float-label">
            <InputText
              className="w-full"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              disabled={isLoading}
            />
            <label htmlFor="name">Nombre</label>
          </span>

          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => setName("")}
          />
        </div>
        <div className="col-12 md:col-6  p-inputgroup mt-4 ">
          <span className="p-float-label">
            <InputText
              className="w-full"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              disabled={isLoading}
            />
            <label htmlFor="email">Correo</label>
          </span>

          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => setEmail("")}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="p-card flex w-full p-3">
      <DataTable
        value={data}
        header={header()}
        paginator
        rows={10}
        dataKey="_id"
        loading={isLoading}
        emptyMessage="No se encontraron asociaciones."
        onRowClick={handleSelectAssociations}
        selectionMode="single"
        className="w-full"
      >
        <Column
          field="name"
          header="Nombre"
          style={{ minWidth: "250px" }}
          body={AssociationNameBodyTemplate}
        />
        <Column field="email" header="Correo" style={{ minWidth: "250px" }} />
        <Column
          header="País"
          style={{ minWidth: "250px" }}
          body={CountryBodyTemplate}
        />

        {/* <Column
          header="Dirección"
          field="address"
          style={{ minWidth: "250px" }}
          filter
          filterPlaceholder="Dirección"
          filterMatchMode="startsWith"
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        /> */}

        <Column
          header="Activado"
          field="activated"
          style={{ minWidth: "50px" }}
          dataType="boolean"
          body={ActivatedBodyTemplate}
        />
      </DataTable>
    </div>
  );
};
