import React, { useContext } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { PrimeReactContext } from "primereact/api";
import { ThemeStateProps } from "../../models/theme";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { Themes } from "../../models/enums/enums";
import { actions } from "../../store/slices/ThemeSlice";

const ThemeButton = () => {
  const { theme } = useAppSelector(
    (state: { theme: ThemeStateProps }) => state.theme,
    shallowEqual
  );
  const dispatch = useAppDispatch();
  const { changeTheme } = useContext(PrimeReactContext);

  return (
    <div className="flex align-items-center pl-3">
      <InputSwitch
        checked={theme === Themes.Light}
        onChange={() => {
          if (changeTheme) {
            const newTheme =
              theme === Themes.Light ? Themes.Dark : Themes.Light;
            changeTheme(
              `md-${theme}-indigo`,
              `md-${newTheme}-indigo`,
              "app-theme",
              () => dispatch(actions.changeTheme(newTheme))
            );
          }
        }}
      />
      <p className="pl-2">Tema Claro</p>
    </div>
  );
};

export default ThemeButton;
