import React from "react";
import { useGetUserReqQuery } from "../../../store/services/monthly-sport-fee";
import ReqTabs from "../../../components/ReqTabs/ReqTabs";
import { useAppSelector } from "../../../hooks/ReduxHooks";
import { AuthStateProps } from "../../../models/auth";
import { shallowEqual } from "react-redux";
import CompleteProfile from "../../../components/CompleteProfile/CompleteProfile";

export const SportRequests = () => {
  const { user } = useAppSelector(
    (state: { auth: AuthStateProps }) => state.auth,
    shallowEqual
  );

  const { data, isLoading, refetch } = useGetUserReqQuery(user?._id || "");

  return (
    <div className="p-card gap-4 p-3">
      <h1 className="text-center mt-4">Mís solicitudes</h1>
      {user?.complete ? (
        <ReqTabs
          data={data || []}
          isLoading={isLoading}
          mode="user"
          refetch={refetch}
        />
      ) : (
        <CompleteProfile />
      )}
    </div>
  );
};
