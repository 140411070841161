import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export const usePanelItems = () => {
  const navigate = useNavigate();

  const items = [
    {
      label: "Dashboard",
      icon: "pi pi-chart-bar",
      command: () => navigate("/panel"),
      path: "/panel",
    },
    {
      label: "Usuarios",
      icon: "pi pi-user",
      btnRef: useRef<any>(null),
      items: [
        {
          label: "Ver usuarios",
          icon: "pi pi-users",
          command: () => navigate("/panel/users"),
          path: "/panel/users",
        },
        {
          label: "Nuevo usuario",
          icon: "pi pi-user-plus",
          command: () => navigate("/panel/users/new"),
          path: "/panel/users/new",
        },
      ],
    },
    {
      label: "Deportes",
      icon: "pi pi-discord",
      btnRef: useRef<any>(null),
      items: [
        {
          label: "Ver cuotas",
          icon: "pi pi-clipboard",
          command: () => navigate("/panel/sports"),
          path: "/panel/sports",
        },
        {
          label: "Ver solicitudes",
          icon: "pi pi-bell",
          command: () => navigate("/panel/sports/requests"),
          path: "/panel/sports/requests",
        },
        {
          label: "Buscar deportes",
          icon: "pi pi-search",
          command: () => navigate("/panel/sports/search"),
          path: "/panel/sports/search",
        },
      ],
    },
    {
      label: "Asociaciones",
      icon: "pi pi-th-large",
      btnRef: useRef<any>(null),
      items: [
        {
          label: "Ver asociaciones",
          icon: "pi pi-list",
          command: () => navigate("/panel/associations"),
          path: "/panel/associations",
        },
        {
          label: "Nueva asociación",
          icon: "pi pi-plus",
          command: () => navigate("/panel/associations/new"),
          path: "/panel/associations/new",
        },
      ],
    },
    // {
    //   label: "Eventos",
    //   icon: "pi pi-calendar",
    //   btnRef: useRef<any>(null),
    //   items: [
    //     {
    //       label: "Nuevo evento",
    //       icon: "pi pi-calendar-plus",
    //       path: "/panel/users/new",
    //     },
    //     {
    //       label: "Ver eventos",
    //       icon: "pi pi-calendar-minus",
    //       path: "/panel/users/new",
    //     },
    //   ],
    // },
    {
      label: "Pagos",
      icon: "pi pi-money-bill",
      btnRef: useRef<any>(null),
      items: [
        {
          label: "Nuevo pago",
          icon: "pi pi-dollar",
          path: "/panel/users/new",
        },
        {
          label: "Ver pagos",
          icon: "pi pi-credit-card",
          path: "/panel/users/new",
        },
      ],
    },
  ];

  return items;
};
