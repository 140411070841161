import React from "react";
import { useGetUserMonthlySPQuery } from "../../store/services/monthly-sport-fee";
import { useAppSelector } from "../../hooks/ReduxHooks";
import { shallowEqual } from "react-redux";
import { AuthStateProps } from "../../models/auth";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  DateBodyTemplate,
  DebtBodyTemplate,
  ExpiratedBodyTemplate,
  PriceBodyTemplate,
} from "../DataTable";
import CompleteProfile from "../CompleteProfile/CompleteProfile";

const UserSportFees = () => {
  const { user } = useAppSelector(
    (state: { auth: AuthStateProps }) => state.auth,
    shallowEqual
  );
  const { data, isLoading } = useGetUserMonthlySPQuery(user?._id || "");

  return user?.complete ? (
    <DataTable
      value={data}
      loading={isLoading}
      paginator
      rows={10}
      rowsPerPageOptions={[5, 10, 25]}
      scrollable
      scrollHeight="600px"
      dataKey="MonthlySportsFeesId"
      globalFilterFields={[
        "firstName",
        "sportName",
        "price",
        "debt",
        "expirated",
        "status",
      ]}
      emptyMessage="No se encontraron cuotas."
    >
      <Column
        field="sportName"
        header="Deporte"
        filter
        filterPlaceholder="Deporte"
        style={{ minWidth: "200px" }}
        filterMatchMode="startsWith"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        showApplyButton={false}
        showClearButton={false}
      />
      <Column
        field="createDate"
        header="Inicio"
        filter
        filterPlaceholder="createDate"
        style={{ minWidth: "150px" }}
        filterMatchMode="startsWith"
        body={DateBodyTemplate}
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        showApplyButton={false}
        showClearButton={false}
      />
      <Column
        field="price"
        header="Precio"
        filter
        filterPlaceholder="Precio"
        style={{ minWidth: "100px" }}
        body={PriceBodyTemplate}
        filterMatchMode="startsWith"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        showApplyButton={false}
        showClearButton={false}
      />
      <Column
        field="debt"
        header="Deuda"
        filter
        filterPlaceholder="Precio"
        style={{ minWidth: "100px" }}
        body={DebtBodyTemplate}
        filterMatchMode="startsWith"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        showApplyButton={false}
        showClearButton={false}
      />
      <Column
        field="expirated"
        header="Vence día"
        filter
        filterPlaceholder="Vencimiento"
        style={{ minWidth: "80px" }}
        align={"center"}
        filterMatchMode="startsWith"
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        showApplyButton={false}
        showClearButton={false}
      />
      <Column
        field="status"
        header="Estado"
        filter
        filterPlaceholder="Estado"
        style={{ minWidth: "100px" }}
        filterMatchMode="equals"
        body={ExpiratedBodyTemplate}
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        showApplyButton={false}
        showClearButton={false}
      />
    </DataTable>
  ) : (
    <CompleteProfile />
  );
};

export default UserSportFees;
