import { MonthlyFee, SeveritiesTypes } from "./enums/enums";

export const expiratedValues = [
  {
    value: MonthlyFee.UNPAID,
    severity: SeveritiesTypes.INFO,
    message: "Sin deuda",
  },
  {
    value: MonthlyFee.DEFEATED,
    severity: SeveritiesTypes.DANGER,
    message: "Vencida",
  },
  {
    value: MonthlyFee.FOR_DEFEATED,
    severity: SeveritiesTypes.WARNING,
    message: "Por vencer",
  },
  {
    value: MonthlyFee.PAID,
    severity: SeveritiesTypes.SUCCESS,
    message: "Pagada",
  },
  {
    value: MonthlyFee.OVERPAIMENT,
    severity: SeveritiesTypes.INFO,
    message: "P/Excedido",
  },
  {
    value: MonthlyFee.WITHOUT,
    severity: SeveritiesTypes.INFO,
    message: "Sin cuota",
  },
];
