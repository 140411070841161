/* eslint-disable react-hooks/exhaustive-deps */
import { PanelMenu } from "primereact/panelmenu";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Button } from "primereact/button";
import { usePanelItems } from "../../../hooks/usePanelItems";
import { useResizeListener } from "primereact/hooks";
import { useAppDispatch, useAppSelector } from "../../../hooks/ReduxHooks";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useSignInByIdMutation } from "../../../store/services/auth";
import { SpinnerPage } from "../../../components/SpinnerPage/SpinnerPage";
import { actions } from "../../../store/slices/AuthSlice";
import { fetchAuthSession } from "aws-amplify/auth";
import { shallowEqual } from "react-redux";
import { AuthStateProps } from "../../../models/auth";

export const Panel = () => {
  const dispatch = useAppDispatch();

  const authData = useAppSelector(
    (state: { auth: AuthStateProps }) => state.auth,
    shallowEqual
  );

  const { signOut } = useAuthenticator();
  const [signInById, { isLoading }] = useSignInByIdMutation();

  async function currentSession() {
    try {
      const { tokens, userSub } = await fetchAuthSession();
      const idToken = tokens?.idToken?.toString() ?? "";
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("userId", userSub || "");
      return { idToken, userSub: userSub || "" };
    } catch (err) {
      console.error("Error fetching session: ", err);
      return { idToken: "", userSub: "" }; // Garantiza devolver siempre strings
    }
  }

  const [showPanel, setShowPanel] = useState(true);
  const panelItems = usePanelItems();

  const handlePanel = () => setShowPanel((prev) => !prev);

  const [bindWindowResizeListener, unbindWindowResizeListener] =
    useResizeListener({
      listener: (event: any) => {
        if (event.currentTarget.innerWidth < 768) setShowPanel(false);
      },
    });

  useEffect(() => {
    bindWindowResizeListener();
    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);

  useLayoutEffect(() => {
    async function loadSession() {
      const { idToken, userSub } = await currentSession();
      if (userSub && idToken && !authData.user) {
        signInById(userSub)
          .unwrap()
          .then((data) => dispatch(actions.signIn(data)))
          .catch(() => {
            dispatch(actions.signOut());
            signOut();
          });
      }
    }
    loadSession();
  }, []);

  return (
    <>
      <div
        className={`flex min-w-full ${showPanel ? "flex-row" : "flex-column"}`}
        style={{ minHeight: "80vh" }}
      >
        <div
          className={`hidden md:block ${
            showPanel ? "p-card w-18rem fadeinleft" : ""
          } p-2`}
        >
          <Button
            style={{ marginBottom: 10 }}
            icon={`pi pi-arrow-${showPanel ? "left" : "right"}`}
            label="Panel"
            onClick={handlePanel}
          />
          {showPanel && <PanelMenu model={panelItems} multiple />}
        </div>
        <div className={`hidden md:block w-full ${showPanel ? " ml-2" : ""} `}>
          {isLoading ? (
            <div className="flex mt-8 flex-column justify-content-center align-content-center">
              <SpinnerPage />
              <h2 className="text-center">
                Obteniendo información de usuario...
              </h2>
            </div>
          ) : (
            <Outlet />
          )}
        </div>
        <div className="md:hidden w-full">
          {isLoading ? (
            <div className="flex mt-8 flex-column justify-content-center align-content-center">
              <SpinnerPage />
              <h2 className="text-center">
                Obteniendo información de usuario...
              </h2>
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </>
  );
};
