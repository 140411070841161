import React, { useMemo, useState } from "react";
import { useGetSportsQuery } from "../../../store/services/sports";
import { SportFetch } from "../../../models/sports";
import { DataView } from "primereact/dataview";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import countriesAndRegions from "../../../utils/jsons/countriesAndRegions.json";
import { CountryBodyTemplate } from "../../../components/DataTable";
import { convertToPrice } from "../../../utils/functions/currencies";
import { sportsTypes } from "../../../utils/constants/forms-constans";
import { useCreateMonthlySPMutation } from "../../../store/services/monthly-sport-fee";
import { actions } from "../../../store/slices/ToastSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/ReduxHooks";
import { Dialog } from "primereact/dialog";
import { AuthStateProps } from "../../../models/auth";
import { shallowEqual } from "react-redux";
import CompleteProfile from "../../../components/CompleteProfile/CompleteProfile";

export const SearchSports = () => {
  const { user } = useAppSelector(
    (state: { auth: AuthStateProps }) => state.auth,
    shallowEqual
  );

  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [reqSport, setReqSport] = useState<SportFetch>();
  const dispatch = useAppDispatch();

  const {
    data,
    isLoading: isLoadingQuery,
    refetch,
  } = useGetSportsQuery({
    name,
    country,
    province,
    city,
    address,
  });

  const [createMonthlySP, { isLoading: isLoadingReq }] =
    useCreateMonthlySPMutation();
  const handleSport = ({ value }: DropdownChangeEvent) => setName(value);
  const handleCountry = ({ value }: DropdownChangeEvent) => setCountry(value);
  const handleProvince = ({ value }: DropdownChangeEvent) => setProvince(value);

  const handleReq = ({ associationId, _id }: SportFetch) => {
    setReqSport(undefined);
    createMonthlySP({
      associationId,
      sportId: _id,
      userId: user?._id || "",
      associationAcceptance: false,
      userAcceptance: true,
    })
      .unwrap()
      .then(() => {
        dispatch(
          actions.showSuccessToast({
            title: "Inscribirse a un deporte",
            message: "Se a enviado la solicitud exitosamente a la asociación!.",
          })
        );
        if (refetch) refetch();
      })
      .catch(() =>
        dispatch(
          actions.showErrorToast({
            title: "Inscribirse a un deporte",
            message:
              "Ocurrió un error al intentar solicitar la inscripción del deporte en la asociación, por favor intente más tarde.",
          })
        )
      );
  };

  const regionOptions = useMemo(() => {
    if (country) {
      const result = countriesAndRegions.find(
        (item) => item.countryName === country
      );

      return result?.regions.map((region) => region.name);
    }
    return [];
  }, [country]);
  const header = () => {
    return (
      <div className="grid ">
        <div className="col-12 md:col-6 lg:col-4 p-inputgroup ">
          <span className="p-float-label">
            <Dropdown
              className="w-full"
              id="country"
              name="country"
              value={country}
              onChange={handleCountry}
              filter
              placeholder="Selecciona tu país"
              options={countriesAndRegions.map(
                (countries) => countries.countryName
              )}
              disabled={isLoadingQuery}
            />
            <label htmlFor="country">País</label>
          </span>
          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              setCountry("");
              setProvince("");
              setCity("");
            }}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4 p-inputgroup ">
          <span className="p-float-label">
            <Dropdown
              className="w-full"
              id="province"
              name="province"
              value={province}
              onChange={handleProvince}
              filter
              options={regionOptions}
              panelFooterTemplate={
                !country && (
                  <p className="text-center p-error">
                    Debe seleccionar un país
                  </p>
                )
              }
              disabled={isLoadingQuery}
            />
            <label htmlFor="province">Provincia</label>
          </span>
          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              setProvince("");
              setCity("");
            }}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4 p-inputgroup ">
          <span className="p-float-label">
            <InputText
              className="w-full"
              id="city"
              name="city"
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
              disabled={isLoadingQuery}
            />
            <label htmlFor="city">Ciudad</label>
          </span>

          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => setCity("")}
          />
        </div>
        <div className="col-12 md:col-6  p-inputgroup">
          <span className="p-float-label">
            <Dropdown
              className="w-full"
              id="name"
              name="name"
              value={name}
              onChange={handleSport}
              filter
              placeholder="Selecciona un deporte"
              options={[...sportsTypes]}
              disabled={isLoadingQuery}
            />
            <label htmlFor="name">Deporte</label>
          </span>
          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => setName("")}
          />
        </div>
        <div className="col-12 md:col-6  p-inputgroup ">
          <span className="p-float-label">
            <InputText
              className="w-full"
              id="address"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.currentTarget.value)}
              disabled={isLoadingQuery}
            />
            <label htmlFor="address">Dirección</label>
          </span>

          <Button
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => setAddress("")}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="p-card gap-4 p-3">
      <div className="flex w-full justify-content-center align-items-center gap-3 mt-4">
        <h1 className="text-center">Deportes</h1>
      </div>
      {user?.complete ? (
        <>
          <DataView
            loading={isLoadingQuery || isLoadingReq}
            value={data || []}
            paginator
            rows={5}
            emptyMessage="Sin eventos"
            header={header()}
            itemTemplate={(sport: SportFetch) => {
              return (
                <div className="col-12">
                  <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                      <div className="flex flex-column align-items-center sm:align-items-start">
                        <h1 className="text-2xl font-bold text-900 m-0 mt-2">
                          {sport.name}
                        </h1>
                        <p className="text-lg font-normal text-700 m-0 p-0">
                          {sport.description}
                        </p>
                        <p className="text-lg font-semibold text-900 m-0 p-0">
                          Club: {sport.associationName}
                        </p>
                        <p className="text-sm font-medium text-400 m-0 p-0">
                          <i
                            data-pr-tooltip="Ubicación"
                            className="pi pi-map-marker text-red-700"
                          />{" "}
                          {sport.address}
                        </p>
                        {CountryBodyTemplate(sport)}
                      </div>
                      <div className="flex sm:flex-column align-items-center sm:align-items-end">
                        <span className="text-2xl font-semibold">
                          {convertToPrice(sport.price)}
                        </span>
                        <span className="text-2xl font-semibold">
                          Vence: {sport.expirated}
                        </span>
                        <Button
                          loading={isLoadingReq}
                          icon="pi pi-ticket"
                          label="Inscribirme"
                          onClick={() => setReqSport(sport)}
                        ></Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
          {reqSport && (
            <Dialog
              header="Inscribirse a deporte"
              visible={!!reqSport}
              style={{ width: "50%", minWidth: 300 }}
              onHide={() => setReqSport(undefined)}
              footer={
                <div className="flex justify-content-end gap-3">
                  <Button
                    label="Envíar solicitud"
                    onClick={() => handleReq(reqSport)}
                    severity="success"
                  />
                  <Button
                    label="cancelar"
                    onClick={() => setReqSport(undefined)}
                    severity="secondary"
                  />
                </div>
              }
            >
              <h3 className="text-red-700">
                ¡Advertencia! <i className="pi pi-exclamation-triangle"></i>
              </h3>
              <p>Solo puedes tener 3 solicitudes activas.</p>
              <p>
                Si no estas seguro o tienes dudas, te recomendamos que te
                comuniques con el club seleccionado.
              </p>
              <p>
                La solicitud será enviada al club y ellos decidiran si
                aceptarla. En caso de ser aceptada, puede comenzar ese deporte y
                pagar la cuota.
              </p>
              <p className="m-0">
                ¿Estas seguro que deseas inscribirte a <b>{reqSport.name}</b> en{" "}
                <b>{reqSport.associationName}</b>?
              </p>
            </Dialog>
          )}
        </>
      ) : (
        <CompleteProfile />
      )}
    </div>
  );
};
